import React from 'react';

const PrivacyPolicy = () => {
    const htmlContent = {
        __html: `
    <html>

    <head>
        <meta content="text/html; charset=UTF-8" http-equiv="content-type">
        <style type="text/css">
            .lst-kix_nojpgd9zcmj9-2>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_nojpgd9zcmj9-3>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_nojpgd9zcmj9-5>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_nojpgd9zcmj9-4>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_9cxyu2n20jyl-0>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_nojpgd9zcmj9-1>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_nojpgd9zcmj9-0>li:before {
                content: "\x025cf   "
            }
    
            ul.lst-kix_1eyxp2msn2vs-0 {
                list-style-type: none
            }
    
            ul.lst-kix_1eyxp2msn2vs-2 {
                list-style-type: none
            }
    
            ul.lst-kix_1eyxp2msn2vs-1 {
                list-style-type: none
            }
    
            ul.lst-kix_1eyxp2msn2vs-4 {
                list-style-type: none
            }
    
            ul.lst-kix_1eyxp2msn2vs-3 {
                list-style-type: none
            }
    
            .lst-kix_njyocjxfh4xn-1>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_njyocjxfh4xn-3>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_njyocjxfh4xn-2>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_njyocjxfh4xn-7>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_njyocjxfh4xn-0>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_njyocjxfh4xn-8>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_1eyxp2msn2vs-0>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_a1oo17chbcad-8>li:before {
                content: "\x025a0   "
            }
    
            ul.lst-kix_nojpgd9zcmj9-8 {
                list-style-type: none
            }
    
            ul.lst-kix_nojpgd9zcmj9-7 {
                list-style-type: none
            }
    
            .lst-kix_vd1p6sewrg5x-1>li:before {
                content: "-  "
            }
    
            ul.lst-kix_nojpgd9zcmj9-4 {
                list-style-type: none
            }
    
            ul.lst-kix_nojpgd9zcmj9-3 {
                list-style-type: none
            }
    
            ul.lst-kix_nojpgd9zcmj9-6 {
                list-style-type: none
            }
    
            ul.lst-kix_nojpgd9zcmj9-5 {
                list-style-type: none
            }
    
            .lst-kix_vd1p6sewrg5x-0>li:before {
                content: "-  "
            }
    
            .lst-kix_1eyxp2msn2vs-7>li:before {
                content: "\x025cb   "
            }
    
            ul.lst-kix_nojpgd9zcmj9-0 {
                list-style-type: none
            }
    
            ul.lst-kix_mat4xjrh05b4-7 {
                list-style-type: none
            }
    
            .lst-kix_a1oo17chbcad-2>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_a1oo17chbcad-3>li:before {
                content: "\x025cf   "
            }
    
            ul.lst-kix_mat4xjrh05b4-6 {
                list-style-type: none
            }
    
            .lst-kix_1eyxp2msn2vs-6>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_1eyxp2msn2vs-8>li:before {
                content: "\x025a0   "
            }
    
            ul.lst-kix_nojpgd9zcmj9-2 {
                list-style-type: none
            }
    
            ul.lst-kix_nojpgd9zcmj9-1 {
                list-style-type: none
            }
    
            .lst-kix_bsunmglz8wk4-1>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_bsunmglz8wk4-3>li:before {
                content: "\x025cf   "
            }
    
            ul.lst-kix_mat4xjrh05b4-8 {
                list-style-type: none
            }
    
            .lst-kix_vd1p6sewrg5x-3>li:before {
                content: "-  "
            }
    
            .lst-kix_vd1p6sewrg5x-5>li:before {
                content: "-  "
            }
    
            ul.lst-kix_vd1p6sewrg5x-7 {
                list-style-type: none
            }
    
            .lst-kix_a1oo17chbcad-0>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_a1oo17chbcad-4>li:before {
                content: "\x025cb   "
            }
    
            ul.lst-kix_vd1p6sewrg5x-8 {
                list-style-type: none
            }
    
            ul.lst-kix_vd1p6sewrg5x-5 {
                list-style-type: none
            }
    
            .lst-kix_bsunmglz8wk4-2>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_bsunmglz8wk4-6>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_vd1p6sewrg5x-2>li:before {
                content: "-  "
            }
    
            .lst-kix_vd1p6sewrg5x-6>li:before {
                content: "-  "
            }
    
            ul.lst-kix_vd1p6sewrg5x-6 {
                list-style-type: none
            }
    
            .lst-kix_1eyxp2msn2vs-3>li:before {
                content: "\x025cf   "
            }
    
            ul.lst-kix_vd1p6sewrg5x-3 {
                list-style-type: none
            }
    
            .lst-kix_a1oo17chbcad-6>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_a1oo17chbcad-7>li:before {
                content: "\x025cb   "
            }
    
            ul.lst-kix_vd1p6sewrg5x-4 {
                list-style-type: none
            }
    
            .lst-kix_1eyxp2msn2vs-2>li:before {
                content: "\x025a0   "
            }
    
            ul.lst-kix_mat4xjrh05b4-1 {
                list-style-type: none
            }
    
            ul.lst-kix_vd1p6sewrg5x-1 {
                list-style-type: none
            }
    
            .lst-kix_bsunmglz8wk4-7>li:before {
                content: "\x025cb   "
            }
    
            ul.lst-kix_mat4xjrh05b4-0 {
                list-style-type: none
            }
    
            ul.lst-kix_vd1p6sewrg5x-2 {
                list-style-type: none
            }
    
            .lst-kix_1eyxp2msn2vs-1>li:before {
                content: "\x025cb   "
            }
    
            ul.lst-kix_mat4xjrh05b4-3 {
                list-style-type: none
            }
    
            .lst-kix_a1oo17chbcad-5>li:before {
                content: "\x025a0   "
            }
    
            ul.lst-kix_mat4xjrh05b4-2 {
                list-style-type: none
            }
    
            ul.lst-kix_vd1p6sewrg5x-0 {
                list-style-type: none
            }
    
            ul.lst-kix_mat4xjrh05b4-5 {
                list-style-type: none
            }
    
            .lst-kix_bsunmglz8wk4-0>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_bsunmglz8wk4-8>li:before {
                content: "\x025a0   "
            }
    
            ul.lst-kix_mat4xjrh05b4-4 {
                list-style-type: none
            }
    
            .lst-kix_vd1p6sewrg5x-4>li:before {
                content: "-  "
            }
    
            .lst-kix_nojpgd9zcmj9-7>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_nojpgd9zcmj9-6>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_njyocjxfh4xn-6>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_vd1p6sewrg5x-8>li:before {
                content: "-  "
            }
    
            .lst-kix_1eyxp2msn2vs-4>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_njyocjxfh4xn-5>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_bsunmglz8wk4-5>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_vd1p6sewrg5x-7>li:before {
                content: "-  "
            }
    
            .lst-kix_1eyxp2msn2vs-5>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_a1oo17chbcad-1>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_nojpgd9zcmj9-8>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_njyocjxfh4xn-4>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_bsunmglz8wk4-4>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_26pafhgor9ig-6>li:before {
                content: "\x025cf   "
            }
    
            ul.lst-kix_26pafhgor9ig-8 {
                list-style-type: none
            }
    
            ul.lst-kix_26pafhgor9ig-7 {
                list-style-type: none
            }
    
            .lst-kix_26pafhgor9ig-3>li:before {
                content: "\x025cf   "
            }
    
            ul.lst-kix_26pafhgor9ig-6 {
                list-style-type: none
            }
    
            .lst-kix_26pafhgor9ig-7>li:before {
                content: "\x025cb   "
            }
    
            ul.lst-kix_26pafhgor9ig-5 {
                list-style-type: none
            }
    
            .lst-kix_lv1clugm7r8y-8>li:before {
                content: "\x025a0   "
            }
    
            ul.lst-kix_njyocjxfh4xn-0 {
                list-style-type: none
            }
    
            ul.lst-kix_26pafhgor9ig-4 {
                list-style-type: none
            }
    
            ul.lst-kix_njyocjxfh4xn-1 {
                list-style-type: none
            }
    
            ul.lst-kix_26pafhgor9ig-3 {
                list-style-type: none
            }
    
            ul.lst-kix_njyocjxfh4xn-2 {
                list-style-type: none
            }
    
            ul.lst-kix_26pafhgor9ig-2 {
                list-style-type: none
            }
    
            ul.lst-kix_njyocjxfh4xn-3 {
                list-style-type: none
            }
    
            ul.lst-kix_26pafhgor9ig-1 {
                list-style-type: none
            }
    
            .lst-kix_lv1clugm7r8y-7>li:before {
                content: "\x025cb   "
            }
    
            ul.lst-kix_njyocjxfh4xn-4 {
                list-style-type: none
            }
    
            ul.lst-kix_26pafhgor9ig-0 {
                list-style-type: none
            }
    
            .lst-kix_26pafhgor9ig-4>li:before {
                content: "\x025cb   "
            }
    
            ul.lst-kix_njyocjxfh4xn-5 {
                list-style-type: none
            }
    
            ul.lst-kix_njyocjxfh4xn-6 {
                list-style-type: none
            }
    
            .lst-kix_26pafhgor9ig-5>li:before {
                content: "\x025a0   "
            }
    
            ul.lst-kix_njyocjxfh4xn-7 {
                list-style-type: none
            }
    
            .lst-kix_lv1clugm7r8y-6>li:before {
                content: "\x025cf   "
            }
    
            ul.lst-kix_njyocjxfh4xn-8 {
                list-style-type: none
            }
    
            .lst-kix_lv1clugm7r8y-3>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_lv1clugm7r8y-5>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_mat4xjrh05b4-0>li:before {
                content: "\x025cf   "
            }
    
            ul.lst-kix_a1oo17chbcad-0 {
                list-style-type: none
            }
    
            .lst-kix_lv1clugm7r8y-0>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_lv1clugm7r8y-4>li:before {
                content: "\x025cb   "
            }
    
            ul.lst-kix_a1oo17chbcad-1 {
                list-style-type: none
            }
    
            .lst-kix_26pafhgor9ig-2>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_mat4xjrh05b4-2>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_lv1clugm7r8y-1>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_26pafhgor9ig-0>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_26pafhgor9ig-1>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_mat4xjrh05b4-1>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_lv1clugm7r8y-2>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_mat4xjrh05b4-6>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_mat4xjrh05b4-4>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_mat4xjrh05b4-8>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_mat4xjrh05b4-3>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_mat4xjrh05b4-7>li:before {
                content: "\x025cb   "
            }
    
            ul.lst-kix_a1oo17chbcad-2 {
                list-style-type: none
            }
    
            ul.lst-kix_a1oo17chbcad-3 {
                list-style-type: none
            }
    
            ul.lst-kix_a1oo17chbcad-4 {
                list-style-type: none
            }
    
            ul.lst-kix_a1oo17chbcad-5 {
                list-style-type: none
            }
    
            ul.lst-kix_a1oo17chbcad-6 {
                list-style-type: none
            }
    
            ul.lst-kix_a1oo17chbcad-7 {
                list-style-type: none
            }
    
            ul.lst-kix_a1oo17chbcad-8 {
                list-style-type: none
            }
    
            .lst-kix_mat4xjrh05b4-5>li:before {
                content: "\x025a0   "
            }
    
            ul.lst-kix_lv1clugm7r8y-1 {
                list-style-type: none
            }
    
            ul.lst-kix_lv1clugm7r8y-0 {
                list-style-type: none
            }
    
            ul.lst-kix_1eyxp2msn2vs-6 {
                list-style-type: none
            }
    
            ul.lst-kix_1eyxp2msn2vs-5 {
                list-style-type: none
            }
    
            ul.lst-kix_lv1clugm7r8y-8 {
                list-style-type: none
            }
    
            ul.lst-kix_1eyxp2msn2vs-8 {
                list-style-type: none
            }
    
            ul.lst-kix_lv1clugm7r8y-7 {
                list-style-type: none
            }
    
            ul.lst-kix_1eyxp2msn2vs-7 {
                list-style-type: none
            }
    
            ul.lst-kix_lv1clugm7r8y-6 {
                list-style-type: none
            }
    
            ul.lst-kix_lv1clugm7r8y-5 {
                list-style-type: none
            }
    
            ul.lst-kix_lv1clugm7r8y-4 {
                list-style-type: none
            }
    
            ul.lst-kix_lv1clugm7r8y-3 {
                list-style-type: none
            }
    
            ul.lst-kix_lv1clugm7r8y-2 {
                list-style-type: none
            }
    
            ul.lst-kix_bsunmglz8wk4-2 {
                list-style-type: none
            }
    
            ul.lst-kix_9cxyu2n20jyl-1 {
                list-style-type: none
            }
    
            ul.lst-kix_bsunmglz8wk4-1 {
                list-style-type: none
            }
    
            ul.lst-kix_9cxyu2n20jyl-0 {
                list-style-type: none
            }
    
            ul.lst-kix_bsunmglz8wk4-0 {
                list-style-type: none
            }
    
            ul.lst-kix_9cxyu2n20jyl-3 {
                list-style-type: none
            }
    
            ul.lst-kix_9cxyu2n20jyl-2 {
                list-style-type: none
            }
    
            ul.lst-kix_bsunmglz8wk4-6 {
                list-style-type: none
            }
    
            ul.lst-kix_9cxyu2n20jyl-5 {
                list-style-type: none
            }
    
            ul.lst-kix_bsunmglz8wk4-5 {
                list-style-type: none
            }
    
            ul.lst-kix_9cxyu2n20jyl-4 {
                list-style-type: none
            }
    
            ul.lst-kix_bsunmglz8wk4-4 {
                list-style-type: none
            }
    
            ul.lst-kix_9cxyu2n20jyl-7 {
                list-style-type: none
            }
    
            ul.lst-kix_bsunmglz8wk4-3 {
                list-style-type: none
            }
    
            ul.lst-kix_9cxyu2n20jyl-6 {
                list-style-type: none
            }
    
            .lst-kix_9cxyu2n20jyl-6>li:before {
                content: "\x025cf   "
            }
    
            .lst-kix_9cxyu2n20jyl-5>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_9cxyu2n20jyl-7>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_9cxyu2n20jyl-4>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_9cxyu2n20jyl-8>li:before {
                content: "\x025a0   "
            }
    
            .lst-kix_9cxyu2n20jyl-2>li:before {
                content: "\x025a0   "
            }
    
            ul.lst-kix_9cxyu2n20jyl-8 {
                list-style-type: none
            }
    
            ul.lst-kix_bsunmglz8wk4-8 {
                list-style-type: none
            }
    
            .lst-kix_9cxyu2n20jyl-1>li:before {
                content: "\x025cb   "
            }
    
            .lst-kix_9cxyu2n20jyl-3>li:before {
                content: "\x025cf   "
            }
    
            ul.lst-kix_bsunmglz8wk4-7 {
                list-style-type: none
            }
    
            li.li-bullet-0:before {
                margin-left: -18pt;
                white-space: nowrap;
                display: inline-block;
                min-width: 18pt
            }
    
            .lst-kix_26pafhgor9ig-8>li:before {
                content: "\x025a0   "
            }
    
            ol {
                margin: 0;
                padding: 0
            }
    
            table td,
            table th {
                padding: 0
            }
    
            .c0 {
                margin-left: 36pt;
                padding-top: 0pt;
                padding-left: 0pt;
                padding-bottom: 8pt;
                line-height: 1.15;
                orphans: 2;
                widows: 2;
                text-align: justify
            }
    
            .c3 {
                color: #333333;
                font-weight: 400;
                text-decoration: none;
                vertical-align: baseline;
                font-size: 11pt;
                font-family: "Arial";
                font-style: normal
            }
    
            .c2 {
                color: #000000;
                font-weight: 400;
                text-decoration: none;
                vertical-align: baseline;
                font-size: 11pt;
                font-family: "Arial";
                font-style: normal
            }
    
            .c9 {
                padding-top: 0pt;
                padding-bottom: 0pt;
                line-height: 1.15;
                orphans: 2;
                widows: 2;
                text-align: left;
                height: 11pt
            }
    
            .c4 {
                padding-top: 0pt;
                padding-bottom: 8pt;
                line-height: 1.15;
                orphans: 2;
                widows: 2;
                text-align: justify
            }
    
            .c1 {
                padding-top: 15pt;
                padding-bottom: 8pt;
                line-height: 1.1;
                orphans: 2;
                widows: 2;
                text-align: justify
            }
    
            .c5 {
                padding-top: 8pt;
                padding-bottom: 8pt;
                line-height: 1.1;
                orphans: 2;
                widows: 2;
                text-align: justify
            }
    
            .c17 {
                color: #333333;
                text-decoration: none;
                vertical-align: baseline;
                font-size: 27pt;
                font-family: "Arial";
                font-style: normal
            }
    
            .c7 {
                font-weight: 400;
                text-decoration: none;
                vertical-align: baseline;
                font-family: "Arial";
                font-style: normal
            }
    
            .c12 {
                font-weight: 400;
                text-decoration: none;
                vertical-align: baseline;
                font-family: "Arial";
                font-style: italic
            }
    
            .c22 {
                background-color: #ffffff;
                max-width: 451.4pt;
                padding: 72pt 72pt 72pt 72pt
            }
    
            .c18 {
                padding: 0;
                margin: 0
            }
    
            .c15 {
                color: #333333;
                font-size: 18pt
            }
    
            .c14 {
                color: #333333;
                font-size: 13.5pt
            }
    
            .c6 {
                color: #333333;
                font-size: 10.5pt
            }
    
            .c11 {
                margin-left: 36pt;
                padding-left: 0pt
            }
    
            .c19 {
                font-size: 27pt;
                color: #333333
            }
    
            .c13 {
                height: 11pt
            }
    
            .c10 {
                font-weight: 700
            }
    
            .c8 {
                font-size: 11pt
            }
    
            .c21 {
                font-size: 14pt
            }
    
            .c20 {
                color: #333333
            }
    
            .c16 {
                height: 20pt
            }
    
            .title {
                padding-top: 0pt;
                color: #000000;
                font-size: 26pt;
                padding-bottom: 3pt;
                font-family: "Arial";
                line-height: 1.15;
                page-break-after: avoid;
                orphans: 2;
                widows: 2;
                text-align: left
            }
    
            .subtitle {
                padding-top: 0pt;
                color: #666666;
                font-size: 15pt;
                padding-bottom: 16pt;
                font-family: "Arial";
                line-height: 1.15;
                page-break-after: avoid;
                orphans: 2;
                widows: 2;
                text-align: left
            }
    
            li {
                color: #000000;
                font-size: 11pt;
                font-family: "Arial"
            }
    
            p {
                margin: 0;
                color: #000000;
                font-size: 11pt;
                font-family: "Arial"
            }
    
            h1 {
                padding-top: 20pt;
                color: #000000;
                font-size: 20pt;
                padding-bottom: 6pt;
                font-family: "Arial";
                line-height: 1.15;
                page-break-after: avoid;
                orphans: 2;
                widows: 2;
                text-align: left
            }
    
            h2 {
                padding-top: 18pt;
                color: #000000;
                font-size: 16pt;
                padding-bottom: 6pt;
                font-family: "Arial";
                line-height: 1.15;
                page-break-after: avoid;
                orphans: 2;
                widows: 2;
                text-align: left
            }
    
            h3 {
                padding-top: 16pt;
                color: #434343;
                font-size: 14pt;
                padding-bottom: 4pt;
                font-family: "Arial";
                line-height: 1.15;
                page-break-after: avoid;
                orphans: 2;
                widows: 2;
                text-align: left
            }
    
            h4 {
                padding-top: 14pt;
                color: #666666;
                font-size: 12pt;
                padding-bottom: 4pt;
                font-family: "Arial";
                line-height: 1.15;
                page-break-after: avoid;
                orphans: 2;
                widows: 2;
                text-align: left
            }
    
            h5 {
                padding-top: 12pt;
                color: #666666;
                font-size: 11pt;
                padding-bottom: 4pt;
                font-family: "Arial";
                line-height: 1.15;
                page-break-after: avoid;
                orphans: 2;
                widows: 2;
                text-align: left
            }
    
            h6 {
                padding-top: 12pt;
                color: #666666;
                font-size: 11pt;
                padding-bottom: 4pt;
                font-family: "Arial";
                line-height: 1.15;
                page-break-after: avoid;
                font-style: italic;
                orphans: 2;
                widows: 2;
                text-align: left
            }
        </style>
    </head>
    
    <body class="c22 doc-content">
        <h1 class="c1" id="h.mmutqztmz22z"><span class="c10 c19">Privacy Policy</span></h1>
        <p class="c4"><span class="c12 c20 c21">French version below</span></p>
        <p class="c4"><span class="c6">Updated June 19, 2019</span></p>
        <h1 class="c1" id="h.2ti0ibd7agaa"><span class="c3">PREAMBLE</span></h1>
        <h1 class="c1" id="h.kpygsd4v6omk"><span class="c3">Our Privacy Policy (the &quot;Charter&quot;) sets out how we
                treat the information we collect about you when you use the TURN UP Application (&quot;the
                Application&quot;) and how you can control the application. use and disclosure of your personal data.</span>
        </h1>
        <h1 class="c1" id="h.nls34p8xqan8"><span class="c3">We attach great importance to protecting your privacy and
                personal data in the way we create, organize and implement our services. In order to maintain maximum
                protection with regard to the personal data we process, we will comply with the principles established
                by:</span></h1>
        <h1 class="c1" id="h.dd0jg5myv89i"><span class="c3">the OECD Council Recommendation on Guidelines for the Protection
                of Privacy and Transborder Flows of Personal Data</span></h1>
        <h1 class="c1" id="h.gfbooh4vmn4s"><span class="c3">European Directive 95/46 / EC of 24 October 1995 on the
                protection of personal data until its repeal</span></h1>
        <h1 class="c1" id="h.2ceev7anp7c"><span class="c3">Regulation (EU) 2016/679 of the European Parliament and of the
                Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and
                on the free movement of such data, repealing Directive 95/46 / EC as from its implementation (the &quot;New
                Regulation&quot;)</span></h1>
        <h1 class="c1" id="h.kv7o3gjwxa8u"><span class="c3">Law No. 78-17 of 6 January 1978 relating to computers, files and
                freedoms as amended in 2004 (the &quot;Data Protection Act&quot;).</span></h1>
        <h1 class="c1" id="h.3wwqviadkycx"><span class="c3">You are invited to read our Charter carefully. This Policy only
                applies to data collected directly on the Application and not to any data collected through any other
                website or application to which you may be redirected and through which your data would have been
                communicated to us. exclusion of data collected via Facebook.</span></h1>
        <p class="c9"><span class="c2"></span></p>
        <h1 class="c1" id="h.wf95m7sk6ehw"><span class="c3">I. DEFINITIONS</span></h1>
        <h1 class="c1" id="h.y1payxbxjuqz"><span class="c3">&quot;Application&quot; means the mobile application marketed
                under the name &quot;TURN UP&quot; created and published by SAS DATELY running on Android and iOS allowing
                access to User Services.</span></h1>
        <h1 class="c1" id="h.gm2ms0qb5o7n"><span class="c3">&quot;Personal Account&quot; means the account of the User
                allowing him to access the Services of the Application.</span></h1>
        <h1 class="c1" id="h.ktengga4e65d"><span class="c3">&quot;Data&quot; means any information relating to a person
                identified or that can be identified, directly or indirectly collected by DATELY such as the name, surname,
                photo and any other data provided by the User.</span></h1>
        <h1 class="c1" id="h.t95y2zv4ts16"><span class="c3">&quot;Services&quot; means the services provided by DATELY to
                Users, namely: (i) a connection service between Users and (ii) a private chat between Users.</span></h1>
        <h1 class="c1" id="h.e8uzd8iuwrnm"><span class="c8 c20">&quot;DATELY&quot; means the simplified joint-stock company
                with a capital of 1,000 euros registered in the Paris Trade and Companies Register under number </span><span
                class="c6">837 545 086</span><span class="c8 c20">&nbsp;and whose registered office is at 29, rue Henri
                Barbusse 91170 Viry-Ch&acirc;tillon,</span><span class="c6">represented by its Chief Executive Officer
                Patrick Berczelly (contact@turn-up.app), </span><span class="c3">which publishes and manages the Application
                operating the data processing and acting as data controller.</span></h1>
        <h1 class="c1" id="h.g5fql338omc0"><span class="c3">&quot;User (s)&quot; means any person who has opened a Personal
                Account on the Application and uses the Services.</span></h1>
        <h1 class="c1" id="h.8ee7mmxe8003"><span class="c3">&nbsp;</span></h1>
        <h1 class="c1" id="h.y01ahch6ftb1"><span class="c3">II. MANAGING YOUR DATA</span></h1>
        <h1 class="c1" id="h.y09h7uwrl4ng"><span class="c3">1. Data Collection and Processing</span></h1>
        <h1 class="c1" id="h.jsehmkhwf7hq"><span class="c3">Through the Application, we collect the Data that you have
                provided to us by: (i) the creation of your Personal Account on the Application, (ii) your e-mails, or (iii)
                the use of the Services.</span></h1>
        <h1 class="c1" id="h.u6wutr3eizuf"><span class="c3">In this context, we may collect, in particular, the following
                Data depending on the security and use needs of the Application:</span></h1>
        <ul class="c18 lst-kix_vd1p6sewrg5x-0 start">
            <li class="c1 c11 li-bullet-0">
                <h1 id="h.2au6dvquldhy" style="display:inline"><span class="c3">validate your registration and create your
                        personal account</span></h1>
            </li>
            <li class="c1 c11 li-bullet-0">
                <h1 id="h.zfz7crul18kr" style="display:inline"><span class="c3">perform the linking services between the
                        Users</span></h1>
            </li>
            <li class="c1 c11 li-bullet-0">
                <h1 id="h.rkdgouzh5iga" style="display:inline"><span class="c3">allow you to use the Services offered on the
                        Application</span></h1>
            </li>
            <li class="c1 c11 li-bullet-0">
                <h1 id="h.3dlkwxodzp25" style="display:inline"><span class="c3">help us analyze your profile in order to
                        bring you the best service</span></h1>
            </li>
            <li class="c1 c11 li-bullet-0">
                <h1 id="h.3mk2ecgzwb6q" style="display:inline"><span class="c3">measure the number of downloads of the
                        Application and perform statistical analysis to allow us to better understand the use of the
                        Application by Users</span></h1>
            </li>
            <li class="c1 c11 li-bullet-0">
                <h1 id="h.lsu1agku4m4s" style="display:inline"><span class="c3">send you e-mails of information or carry out
                        the operations relating to the commercial prospecting including to carry out operations of
                        solicitation, promotion, loyalty, survey and to send newsletters, being specified that you are
                        informed of the faculty to refuse the collection of your Data to personal nature for this purpose,
                        when collecting them</span></h1>
            </li>
            <li class="c1 c11 li-bullet-0">
                <h1 id="h.v7rll9bp7smy" style="display:inline"><span class="c3">send you push notifications to inform you of
                        any new content available on the Application, it being specified that you have the possibility to
                        deactivate on your phone the sending of such notifications</span></h1>
            </li>
            <li class="c1 c11 li-bullet-0">
                <h1 id="h.wy8oe7ymnm0" style="display:inline"><span class="c3">enforce existing laws and regulations.</span>
                </h1>
            </li>
        </ul>
        <h1 class="c1" id="h.hbfqnbpd3ces"><span class="c3">The data compulsorily collected - in a non-exhaustive way - by
                DATELY are the email address, surname, first name, date of birth, gender and photos.</span></h1>
        <h1 class="c1"><span class="c3">The Application’s use and transfer to any other app of information received from Google Accounts 
        will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">
        Google API Services User Data Policy</a>, including the Limited Use requirements.</span></h1>
        <h1 class="c1" id="h.htrr8uek807i"><span class="c8 c20">DATELY does not collect or process any data relating to
                your</span><span class="c2">&nbsp;means of payment. These are managed directly, if necessary, by the Apple
                App Store, the Google Play Store of Android.</span></h1>
        <h1 class="c1" id="h.mslwv7c1te6t"><span class="c2">DATELY refuses to collect data from minor children. In the event
                of a User&#39;s lying about his date of birth and more particularly in case of fraudulent certification on
                his majority, the parents of the minor child are invited to inform DATELY by sending an email to
                contact@turn-up.app, in order to request the deletion of the data. DATELY undertakes to delete all personal
                data concerning the minor child as soon as possible.</span></h1>
        <h1 class="c1" id="h.wxvo7jz9po1x"><span class="c2">&nbsp;</span></h1>
        <h1 class="c1" id="h.v3aw7uc788th"><span class="c2">III. YOUR RIGHTS - CLAIMS MANAGEMENT</span></h1>
        <h1 class="c1" id="h.sy9hlw5zqrx8"><span class="c2">You have a right of access, rectification, opposition and
                deletion of your Data in accordance with the provisions of articles 39 and 40 of the law n &deg; 78-17 of
                January 6th, 1978 relating to data processing, files and freedoms as amended in 2004.</span></h1>
        <h1 class="c1" id="h.ikyf792nox5f"><span class="c2">To exercise these rights, you can send us an email at
                contact@turn-up.app or a postal mail to the following address: DATELY - Customer Service, 29, rue Henri
                Barbusse 91170 Viry-Chatillon.</span></h1>
        <h1 class="c1" id="h.vpsh3bb0c0b0"><span class="c2">As such, we will ask you to provide us with proof of your
                identity and specify the address to which we will send you the answer. If you are unable to prove your
                identity, the DATELY team reserves the right to refuse to respond to your request.</span></h1>
        <h1 class="c1" id="h.bb3uc0s4fwsh"><span class="c2">The right of access authorizes the User to interrogate DATELY in
                order to obtain the communication of the Data concerning him in an accessible form on the basis of article
                39 of the Data-processing Law and Freedoms.</span></h1>
        <h1 class="c1" id="h.x9wxhdhk89v"><span class="c2">The right of rectification confers on the User the right to
                require that DATELY be rectified, completed, updated or deleted the Personal Data concerning him that are
                inaccurate, incomplete, equivocal, out of date or whose use, communication or preservation is prohibited on
                the basis of Article 40 of the Data Protection Act.</span></h1>
        <h1 class="c1" id="h.e76t8kdsm5o9"><span class="c2">The right of opposition confers on the User the right to oppose,
                free of charge and in a discretionary manner, that his Data be used by DATELY for the purpose of
                prospecting, in particular commercial, on the basis of article 38 of the Data Protection Act.</span></h1>
        <h1 class="c1" id="h.mceqwx5db7ii"><span class="c2">The right of cancellation confers on the User the right to
                demand the free cancellation at no cost to the Data communicated to DATELY, within the limits of the legal
                obligations incumbent on DATELY as hosting provider.</span></h1>
        <h1 class="c1" id="h.vv3hukla8g1c"><span class="c2">We will answer you within two (2) months from the reception of
                your mail and will bring you, if necessary, the justification of our refusal to grant your request.</span>
        </h1>
        <h1 class="c1" id="h.grfcaq89eqmk"><span class="c2">&nbsp;</span></h1>
        <h1 class="c1" id="h.e4d6hv8l2586"><span class="c2">IV. DATA RETENTION</span></h1>
        <h1 class="c1" id="h.2y6vhlvs6qjg"><span class="c2">The Data you have entered in connection with the use of the
                Services are kept by us for the duration of your use of the Services for the purpose of using the
                service.</span></h1>
        <h1 class="c1" id="h.u5ph04793ov"><span class="c2">We retain, however, the Contact Information that allows us to
                manage our business relationship with you for a period of three (3) years from your first use of the
                Services.</span></h1>
        <p class="c9"><span class="c2"></span></p>
        <h1 class="c1" id="h.rdq22hld6za4"><span class="c2">V. RECIPIENTS OF DATA</span></h1>
        <h1 class="c1" id="h.ptk2prly6tdz"><span class="c2">The Data about you that we collect and process is transmitted to
                our Google technical service provider who is bound by the same obligations of security and confidentiality
                in application of the Community provisions for servers located on the territory of the European Union and in
                accordance with the contracts concluded for those outside the territory of the European Union.</span></h1>
        <h1 class="c1" id="h.eulwhz2a34a"><span class="c2">We also communicate some of your Data (first name, age, photo(s))
                to other Users as part of the performance of the Services.</span></h1>
        <h1 class="c1" id="h.h2dg5pv91uoq"><span class="c2">DATELY may also contract with authenticated and reliable
                subcontractors who may access, host and / or process certain personal data of Users on behalf of DATELY,
                according to its instructions, in compliance with the Charter and which guarantee the security and the
                confidentiality of Users&#39; personal data. These subcontractors notably allow DATELY to establish
                statistics on the volumes of use and / or use of the Application and / or ensure the proper functioning of
                the Application.</span></h1>
        <h1 class="c1" id="h.grf5z48hcukk"><span class="c2">Except for the above cases, we will only be able to share and
                share your Data with other providers and for other purposes after you have informed us and obtained your
                consent, as the case may be.</span></h1>
        <h1 class="c1" id="h.dx6iialjjnm6"><span class="c2">The personal data that the User has published in the Application
                are made strictly invisible by DATELY for non-registered users and third parties. The responsibility of
                DATELY can not be sought for data published on DATELY and disclosed by a User.</span></h1>
        <h1 class="c1" id="h.umuepu180hw1"><span class="c2">The profile of the User who does not match the search criteria
                of other Users will not appear on the news feed of these Users.</span></h1>
        <h1 class="c1" id="h.1o07mmegna7t"><span class="c8">Mobile data</span><span class="c2">: When the User uses the
                Application, DATELY collects server logs that may include information such as IP addresses, operating
                system, or application failures. DATELY is unable to access the browsing history of its Users.</span></h1>
        <h1 class="c1" id="h.fref6o65ytus"><span class="c2">&nbsp;</span></h1>
        <p class="c9"><span class="c2"></span></p>
        <h1 class="c1" id="h.z54oa18x8nlx"><span class="c2">VI. SECURITY</span></h1>
        <h1 class="c1" id="h.gezzt5ndfku9"><span class="c2">In accordance with Article 34 of the Data Protection Act, we are
                required to take all the necessary precautions to preserve the security of the Data and prevent it from
                being distorted, damaged, or unauthorized third parties having access to it.</span></h1>
        <h1 class="c1" id="h.u0fcnrtz8x7f"><span class="c2">Our concern is to preserve the quality and integrity of your
                Data. As such and in accordance with CNIL recommendations, we also ensure that our provider has implemented
                measures and / or installed devices to guard against attacks by injection of SQL code, scripts ... to guard
                against all unauthorized access, misuse, alteration, malicious or accidental destruction and unintentional
                loss of data.</span></h1>
        <h1 class="c1" id="h.yvw889guc7e4"><span class="c2">We are committed to maintaining your Confidential Data and
                sharing it only in the above circumstances. As such, we guarantee that your Data are not communicated to
                institutions or governmental authorities, except in the cases provided by the provisions of Law No. 78-17 of
                6 January 1978 relating to data processing, files and freedoms as amended in 2004, including for the purpose
                of responding to legal proceedings of any kind, to court orders, to any legal procedures or enforcement
                actions requested by the competent authorities.</span></h1>
        <h1 class="c1" id="h.5khn3aawgzmu"><span class="c2">&nbsp;</span></h1>
        <h1 class="c1" id="h.dvd74wm5yedz"><span class="c2">VII. NOTIFICATION OF CHANGES</span></h1>
        <h1 class="c1" id="h.pyosz4e21qyi"><span class="c2">The creation of new services on our Application may involve the
                modification of our Charter which we will notify in this section.</span></h1>
        <h1 class="c1" id="h.3v6nj34nzbg2"><span class="c2">In this case, the update date of the Charter will be updated and
                will indicate the day the changes were made.</span></h1>
        <h1 class="c1 c16" id="h.z8r5nfr3b76z"><span class="c2"></span></h1>
        <p class="c9"><span class="c2"></span></p>
        <p class="c9"><span class="c2"></span></p>
        <p class="c9"><span class="c2"></span></p>
        <p class="c9"><span class="c2"></span></p>
        <p class="c9"><span class="c2"></span></p>
        <p class="c9"><span class="c2"></span></p>
        <p class="c9"><span class="c2"></span></p>
        <p class="c9"><span class="c2"></span></p>
        <p class="c9"><span class="c2"></span></p>
        <h1 class="c1 c16" id="h.6ieqffdq8hzf"><span class="c3"></span></h1>
        <p class="c9"><span class="c2"></span></p>
        <h1 class="c1" id="h.asjzutk670p3"><span class="c10 c17">Charte de Confidentialit&eacute;</span></h1>
        <p class="c4"><span class="c6 c12">Mise &agrave; jour le 19 juin 2019</span></p>
        <h3 class="c1" id="h.amt5rwwudlzx"><span class="c7 c15">PR&Eacute;AMBULE</span></h3>
        <p class="c4"><span class="c6">Notre Charte de confidentialit&eacute; (la &laquo; </span><span
                class="c6 c10">Charte</span><span class="c6">&nbsp;&raquo;) fixe la mani&egrave;re dont nous traitons
                l&#39;information que nous recueillons &agrave; votre sujet quand vous utilisez l&rsquo;Application TURN UP
                (&laquo; </span><span class="c6">l&#39;</span><span class="c6 c10">Application</span><span
                class="c7 c6">&nbsp;&raquo;) ainsi que la mani&egrave;re dont vous pouvez contr&ocirc;ler
                l&rsquo;utilisation et la divulgation de vos donn&eacute;es &agrave; caract&egrave;re personnel.</span></p>
        <p class="c4"><span class="c7 c6">Nous attachons une haute importance &agrave; la protection de votre vie
                priv&eacute;e et de vos donn&eacute;es &agrave; caract&egrave;re personnel dans la mani&egrave;re dont nous
                cr&eacute;ons, organisons et mettons en &oelig;uvre nos services. Afin de maintenir une protection maximale
                quant aux donn&eacute;es &agrave; caract&egrave;re personnel que nous traitons, nous nous conformerons aux
                principes &eacute;tablis par :</span></p>
        <ul class="c18 lst-kix_nojpgd9zcmj9-0 start">
            <li class="c0 li-bullet-0"><span class="c7 c6">la recommandation du Conseil de l&rsquo;OCDE concernant les
                    lignes directrices r&eacute;gissant la protection de la vie priv&eacute;e et les flux
                    transfronti&egrave;res de donn&eacute;es &agrave; caract&egrave;re personnel</span></li>
            <li class="c0 li-bullet-0"><span class="c7 c6">la directive Europ&eacute;enne 95/46/CE du 24 octobre 1995 sur la
                    protection des donn&eacute;es &agrave; caract&egrave;re personnel jusqu&rsquo;&agrave; son
                    abrogation</span></li>
            <li class="c0 li-bullet-0"><span class="c6">le R&egrave;glement (UE) 2016/679 du Parlement europ&eacute;en et du
                    Conseil du 27 avril 2016 relatif &agrave; la protection des personnes physiques &agrave;
                    l&#39;&eacute;gard du traitement des donn&eacute;es &agrave; caract&egrave;re personnel et &agrave; la
                    libre circulation de ces donn&eacute;es abrogeant la directive 95/46/CE &agrave; compter de sa mise en
                    application (la &laquo; </span><span class="c6 c10">Nouvelle R&eacute;glementation</span><span
                    class="c7 c6">&nbsp;&raquo;)</span></li>
            <li class="c0 li-bullet-0"><span class="c6">la loi n&deg;78-17 du 6 janvier 1978 relative &agrave;
                    l&rsquo;informatique, aux fichiers et aux libert&eacute;s telle que modifi&eacute;e en 2004 (la &laquo;
                </span><span class="c6 c10">Loi Informatique et Libert&eacute;s</span><span
                    class="c7 c6">&nbsp;&raquo;).</span></li>
        </ul>
        <p class="c4"><span class="c7 c6">Vous &ecirc;tes invit&eacute; &agrave; lire attentivement notre Charte. Cette
                Charte ne s&rsquo;applique qu&rsquo;aux donn&eacute;es recueillies directement sur l&rsquo;Application et
                non pas aux donn&eacute;es recueillies par le biais de tout autre site internet ou application vers lequel
                vous pourriez &ecirc;tre redirig&eacute; et par lequel vos donn&eacute;es nous auraient &eacute;t&eacute;
                communiqu&eacute;es, &agrave; l&rsquo;exclusion des donn&eacute;es recueillies par
                l&rsquo;interm&eacute;diaire de Facebook.</span></p>
        <p class="c4"><span class="c6 c7">&nbsp;</span></p>
        <h3 class="c1" id="h.vf8521m2gi58"><span class="c7 c15">I. D&Eacute;FINITIONS</span></h3>
        <p class="c4"><span class="c6">&laquo; </span><span class="c6 c10">Application</span><span
                class="c7 c6">&nbsp;&raquo; d&eacute;signe l&rsquo;application mobile commercialis&eacute;e sous le nom de
                &laquo; TURN UP &raquo; cr&eacute;&eacute;e et &eacute;dit&eacute;e par la SAS DATELY fonctionnant sur
                Android et iOS permettant l&rsquo;acc&egrave;s aux Services aux Utilisateurs.</span></p>
        <p class="c4"><span class="c6">&laquo; </span><span class="c6 c10">Compte Personnel</span><span
                class="c7 c6">&nbsp;&raquo; d&eacute;signe le compte de l&rsquo;Utilisateur lui permettant
                d&rsquo;acc&eacute;der aux Services de l&rsquo;Application.</span></p>
        <p class="c4"><span class="c6">&laquo; </span><span class="c6 c10">Donn&eacute;es</span><span
                class="c7 c6">&nbsp;&raquo; d&eacute;signe toute information relative &agrave; une personne
                identifi&eacute;e ou qui peut &ecirc;tre identifi&eacute;e, directement ou indirectement collect&eacute;es
                par DATELY tels que le nom, pr&eacute;nom, photographie ainsi que toute autres donn&eacute;e
                communiqu&eacute;e par l&rsquo;Utilisateur.</span></p>
        <p class="c4"><span class="c6">&laquo; </span><span class="c6 c10">Services</span><span class="c7 c6">&nbsp;&raquo;
                d&eacute;signe les services fournis par DATELY aux Utilisateurs &agrave; savoir : (i) un service de mise en
                relation entre Utilisateurs et (ii) un chat priv&eacute; entre Utilisateurs.</span></p>
        <p class="c4"><span class="c6">&laquo; </span><span class="c6 c10">DATELY</span><span class="c6">&nbsp;&raquo;
            </span><span class="c7 c6">d&eacute;signe la soci&eacute;t&eacute; par actions simplifi&eacute;e au capital de
                1.000 euros inscrite au registre du Commerce et des Soci&eacute;t&eacute;s de Paris sous le num&eacute;ro
                837 545 086 et dont le si&egrave;ge social est &eacute;tabli au 29, rue Henri Barbusse 91170
                Viry-Ch&acirc;tillon, repr&eacute;sent&eacute; par le Pr&eacute;sident Patrick Berczelly
                (contact@turn-up.app) qui &eacute;dite et g&egrave;re l&rsquo;Application op&eacute;rant les traitements de
                Donn&eacute;es et agissant en qualit&eacute; de responsable de traitement de donn&eacute;es.</span></p>
        <p class="c4"><span class="c6">&laquo; </span><span class="c6 c10">Utilisateur(s)</span><span
                class="c7 c6">&nbsp;&raquo; d&eacute;signe toute personne ayant ouvert un Compte Personnel sur
                l&rsquo;Application et utilisant les Services.</span></p>
        <p class="c4"><span class="c7 c6">&nbsp;</span></p>
        <h3 class="c1" id="h.tvj08mn86xpi"><span class="c7 c15">II. GESTION DE VOS DONN&Eacute;ES</span></h3>
        <h4 class="c5" id="h.j17d1iqircfm"><span class="c7 c14">1. Collecte et traitement des Donn&eacute;es</span></h4>
        <p class="c4"><span class="c7 c6">Par l&rsquo;interm&eacute;diaire de l&rsquo;Application, nous sommes amen&eacute;s
                &agrave; collecter les Donn&eacute;es que vous nous avez notamment communiqu&eacute;es lors de : (i) la
                cr&eacute;ation de votre Compte Personnel sur l&rsquo;Application, (ii) vos envois de courriers
                &eacute;lectroniques, ou encore (iii) l&rsquo;utilisation des Services.</span></p>
        <p class="c4"><span class="c7 c6">Dans ce cadre, nous pouvons collecter notamment les Donn&eacute;es suivantes en
                fonction des besoins de s&eacute;curit&eacute; et d&rsquo;utilisation de l&rsquo;Application:</span></p>
        <ul class="c18 lst-kix_a1oo17chbcad-0 start">
            <li class="c0 li-bullet-0"><span class="c7 c6">valider votre inscription et cr&eacute;er votre Compte
                    Personnel</span></li>
            <li class="c0 li-bullet-0"><span class="c7 c6">ex&eacute;cuter le services de mise en relation entre les
                    Utilisateurs</span></li>
            <li class="c0 li-bullet-0"><span class="c7 c6">vous permettre d&rsquo;utiliser les Services propos&eacute;s sur
                    l&rsquo;Application</span></li>
            <li class="c0 li-bullet-0"><span class="c7 c6">nous aider &agrave; analyser votre profil aux fins de vous
                    apporter le meilleur service</span></li>
            <li class="c0 li-bullet-0"><span class="c7 c6">mesurer le nombre de t&eacute;l&eacute;chargements de
                    l&rsquo;Application et effectuer des analyses statistiques pour nous permettre de mieux comprendre
                    l&rsquo;utilisation de l&rsquo;Application par les Utilisateurs</span></li>
            <li class="c0 li-bullet-0"><span class="c7 c6">vous envoyer des courriels d&rsquo;information ou effectuer les
                    op&eacute;rations relatives &agrave; la prospection commerciale notamment effectuer des
                    op&eacute;rations de sollicitation, promotion, fid&eacute;lisation, sondage et adresser des newsletters,
                    &eacute;tant pr&eacute;cis&eacute; que vous &ecirc;tes inform&eacute; de la facult&eacute; de refuser la
                    collecte de vos Donn&eacute;es &agrave; caract&egrave;re personnel &agrave; cette fin, lors de la
                    collecte de celles-ci</span></li>
            <li class="c0 li-bullet-0"><span class="c7 c6">vous envoyer des notifications push afin de vous informer de tout
                    nouveau contenu disponible sur l&rsquo;Application, &eacute;tant pr&eacute;cis&eacute; que vous avez la
                    possibilit&eacute; de d&eacute;sactiver sur votre t&eacute;l&eacute;phone l&rsquo;envoi de telles
                    notifications</span></li>
            <li class="c0 li-bullet-0"><span class="c7 c6">faire application de la l&eacute;gislation et de la
                    r&eacute;glementation en vigueur.</span></li>
        </ul>
        <p class="c4"><span class="c7 c6">Les donn&eacute;es obligatoirement collect&eacute;es - de mani&egrave;re non
                exhaustive - par DATELY sont l&rsquo;adresse email, le nom, le pr&eacute;nom, la date de naissance, le genre
                et les photos.</span></p>
        <p class="c4"><span class="c7 c6">L'utilisation par l'Application et le transfert à toute autre application des informations 
        reçues des comptes Google respecteront les règles de 
        <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">
        Politique de données des utilisateurs des services Google API</a>, y compris les exigences relatives à l'utilisation limitée.</span></h1>
        <p class="c4"><span class="c7 c6">DATELY ne collecte ni ne traite aucune donn&eacute;e relative &agrave; vos moyens
                de paiement. Ceux-ci sont g&eacute;r&eacute;s directement, le cas &eacute;ch&eacute;ant, par l&rsquo;App
                Store d&rsquo;Apple, le Google Play Store d&rsquo;Android.</span></p>
        <p class="c4"><span class="c6">DATELY refuse de collecter les donn&eacute;es d&rsquo;enfants mineurs. En cas de
                mensonge d&rsquo;un Utilisateur concernant sa date de naissance et plus particuli&egrave;rement en cas
                d&rsquo;attestation frauduleuse sur sa majorit&eacute;, les parents de l&rsquo;enfant mineur sont
                invit&eacute;s &agrave; avertir DATELY en envoyant un courriel &agrave; </span><span
                class="c6">contact@turn-up.app</span><span class="c7 c6">, afin de demander l&rsquo;effacement des
                donn&eacute;es. DATELY s&rsquo;engage &agrave; supprimer l&rsquo;ensemble des donn&eacute;es personnelles
                concernant l&rsquo;enfant mineur dans les plus brefs d&eacute;lais.</span></p>
        <p class="c4"><span class="c7 c6">&nbsp;</span></p>
        <h3 class="c1" id="h.nhy07x8ubjif"><span class="c7 c15">III. VOS DROITS &ndash; GESTION DES
                R&Eacute;CLAMATIONS</span></h3>
        <p class="c4"><span class="c7 c6">Vous disposez d&rsquo;un droit d&rsquo;acc&egrave;s, de rectification,
                d&rsquo;opposition et de suppression de vos Donn&eacute;es conform&eacute;ment aux dispositions des articles
                39 et 40 de la loi n&deg;78-17 du 6 janvier 1978 relative &agrave; l&rsquo;informatique, aux fichiers et aux
                libert&eacute;s telle que modifi&eacute;e en 2004.</span></p>
        <p class="c4"><span class="c7 c6">Pour exercer ces droits, vous pouvez nous adresser soit un email &agrave;
                contact@turn-up.app, soit un courrier postal &agrave; l&rsquo;adresse suivante: DATELY - Service Client, 29,
                rue Henri Barbusse 91170 Viry-Chatillon.</span></p>
        <p class="c4"><span class="c7 c6">A ce titre, nous vous demanderons de nous fournir une preuve de votre
                identit&eacute; et de pr&eacute;ciser l&rsquo;adresse &agrave; laquelle nous devrons vous faire parvenir la
                r&eacute;ponse. Si vous n&#39;&ecirc;tes pas en mesure de prouver votre identit&eacute;,
                l&rsquo;&eacute;quipe DATELY se r&eacute;servera le droit de refuser de r&eacute;pondre &agrave; votre
                demande.</span></p>
        <ul class="c18 lst-kix_njyocjxfh4xn-0 start">
            <li class="c0 li-bullet-0"><span class="c7 c6">Le droit d&rsquo;acc&egrave;s autorise l&rsquo;Utilisateur
                    &agrave; interroger DATELY en vue d&rsquo;obtenir la communication des Donn&eacute;es le concernant sous
                    une forme accessible sur le fondement de l&rsquo;article 39 de la Loi Informatique et
                    Libert&eacute;s.</span></li>
            <li class="c0 li-bullet-0"><span class="c7 c6">Le droit de rectification conf&egrave;re &agrave;
                    l&rsquo;Utilisateur le droit d&rsquo;exiger de DATELY que soient rectifi&eacute;es,
                    compl&eacute;t&eacute;es, mises &agrave; jour ou effac&eacute;es les Donn&eacute;es &agrave;
                    caract&egrave;re personnel le concernant qui sont inexactes, incompl&egrave;tes, &eacute;quivoques,
                    p&eacute;rim&eacute;es ou dont l&rsquo;utilisation, la communication ou la conservation est interdite
                    sur le fondement de l&rsquo;article 40 de la Loi Informatiques et Libert&eacute;s.</span></li>
            <li class="c0 li-bullet-0"><span class="c7 c6">Le droit d&rsquo;opposition conf&egrave;re &agrave;
                    l&rsquo;Utilisateur le droit de s&rsquo;opposer sans frais et de mani&egrave;re discr&eacute;tionnaire,
                    &agrave; ce que ses Donn&eacute;es soient utilis&eacute;es par DATELY &agrave; des fins de prospection,
                    notamment commerciale, sur le fondement de l&rsquo;article 38 de la Loi Informatique et Libert&eacute;s.
                </span></li>
            <li class="c0 li-bullet-0"><span class="c7 c6">Le droit de suppression conf&egrave;re &agrave;
                    l&rsquo;Utilisateur le droit d&rsquo;exiger l&rsquo;effacement sans frais de mani&egrave;re
                    discr&eacute;tionnaire les Donn&eacute;es communiqu&eacute;es &agrave; DATELY, dans la limite toutefois
                    des obligations l&eacute;gales qui incombent &agrave; DATELY en sa qualit&eacute;
                    d&rsquo;h&eacute;bergeur.</span></li>
        </ul>
        <p class="c4"><span class="c7 c6">Nous vous r&eacute;pondrons dans un d&eacute;lai de deux (2) mois &agrave; compter
                de la r&eacute;ception de votre courrier et vous apporterons, le cas &eacute;ch&eacute;ant, la justification
                de notre refus de faire droit &agrave; votre demande.</span></p>
        <p class="c4"><span class="c7 c6">&nbsp;</span></p>
        <h3 class="c1" id="h.1zc4dm65txx"><span class="c7 c15">IV. CONSERVATION DES DONN&Eacute;ES</span></h3>
        <p class="c4"><span class="c7 c6">Les Donn&eacute;es que vous avez renseign&eacute;es dans le cadre de
                l&rsquo;utilisation des Services sont conserv&eacute;es par nos soins pendant toute la dur&eacute;e de votre
                utilisation des Services pour les besoins de l&rsquo;utilisation dudit service.</span></p>
        <p class="c4"><span class="c7 c6">Nous conservons, toutefois, les Donn&eacute;es relatives &agrave; vos
                coordonn&eacute;es nous permettant de g&eacute;rer notre relation commerciale avec vous pendant un
                d&eacute;lai de trois (3) ans &agrave; compter de votre premi&egrave;re utilisation des Services.</span></p>
        <p class="c4 c13"><span class="c7 c6"></span></p>
        <h3 class="c1" id="h.9tzm6wsoh38p"><span class="c7 c15">V. DESTINATAIRES DES DONN&Eacute;ES</span></h3>
        <p class="c4"><span class="c7 c6">Les Donn&eacute;es vous concernant que nous collectons et que nous traitons sont
                transmises &agrave; notre prestataire technique Google (Firebase, aux Etats-Unis dans l&rsquo;Iowa)
                &nbsp;qui est tenu aux m&ecirc;mes obligations de s&eacute;curit&eacute; et de confidentialit&eacute; en
                application des dispositions communautaires pour les serveurs situ&eacute;s sur le territoire de
                l&rsquo;Union Europ&eacute;enne et conform&eacute;ment aux contrats conclus pour ceux situ&eacute;s en
                dehors du territoire de l&rsquo;Union Europ&eacute;enne.</span></p>
        <p class="c4"><span class="c7 c6">Nous communiquons &eacute;galement certaines de vos Donn&eacute;es (pr&eacute;nom,
                &acirc;ge, photographie(s)) aux autres Utilisateurs dans le cadre de l&rsquo;ex&eacute;cution des
                Services.</span></p>
        <p class="c4"><span class="c7 c6">DATELY peut &eacute;galement contracter avec des sous-traitants
                authentifi&eacute;s et fiables qui sont susceptibles d&rsquo;acc&eacute;der, h&eacute;berger et/ou traiter
                certaines donn&eacute;es personnelles des Utilisateurs pour le compte de DATELY, selon ses instructions,
                dans le respect de la Charte et qui garantissent la s&eacute;curit&eacute; et la confidentialit&eacute; des
                donn&eacute;es personnelles des Utilisateurs. Ces sous-traitants permettent notamment &agrave; DATELY
                d&rsquo;&eacute;tablir des statistiques sur les volumes de fr&eacute;quentation et/ou l&rsquo;utilisation de
                l&rsquo;Application et/ou assurer le bon fonctionnement de l&rsquo;Application. </span></p>
        <p class="c4"><span class="c7 c6">Hormis les cas nomm&eacute;s pr&eacute;c&eacute;demment, nous ne pourrons partager
                et communiquer vos Donn&eacute;es &agrave; d&rsquo;autres prestataires et &agrave; d&rsquo;autres fins
                qu&rsquo;apr&egrave;s vous en avoir inform&eacute; et avoir obtenu, le cas &eacute;ch&eacute;ant, votre
                consentement.</span></p>
        <p class="c4"><span class="c7 c6">Les donn&eacute;es personnelles que l&rsquo;Utilisateur a publi&eacute;es dans
                l&rsquo;Application sont rendues strictement invisibles par DATELY pour les internautes non inscrits et les
                tiers. La responsabilit&eacute; de DATELY ne saurait &ecirc;tre recherch&eacute;e pour les donn&eacute;es
                publi&eacute;es sur DATELY et divulgu&eacute;es par un Utilisateur.</span></p>
        <p class="c4"><span class="c7 c6">Le profil de l&rsquo;Utilisateur qui ne correspond pas aux crit&egrave;res de
                recherches d&rsquo;autres Utilisateurs n&rsquo;appara&icirc;tra pas sur le fil d&rsquo;actualit&eacute; de
                ces Utilisateurs.</span></p>
        <p class="c4"><span class="c7 c6">Donn&eacute;es relatives au mobile : Lorsque l&rsquo;Utilisateur utilise
                l&rsquo;Application, DATELY collecte des journaux serveurs qui peuvent inclure des informations telles que
                les adresses IP, le syst&egrave;me d&rsquo;exploitation ou les pannes d&rsquo;application. DATELY
                n&rsquo;est pas en mesure d&rsquo;acc&eacute;der &agrave; l&rsquo;historique de navigation de ses
                Utilisateurs.</span></p>
        <p class="c4"><span class="c7 c6">&nbsp;</span></p>
        <h3 class="c1" id="h.4fbnpx84ydou"><span class="c7 c15">VI. S&Eacute;CURIT&Eacute;</span></h3>
        <p class="c4"><span class="c7 c6">Conform&eacute;ment &agrave; l&rsquo;article 34 de la Loi Informatique et
                libert&eacute;s, nous sommes tenus de prendre toutes les pr&eacute;cautions utiles pour pr&eacute;server la
                s&eacute;curit&eacute; des Donn&eacute;es et emp&ecirc;cher notamment qu&rsquo;elles soient
                d&eacute;form&eacute;es, endommag&eacute;es, ou que des tiers non autoris&eacute;s y aient
                acc&egrave;s.</span></p>
        <p class="c4"><span class="c7 c6">Notre pr&eacute;occupation est de pr&eacute;server la qualit&eacute; et
                l&#39;int&eacute;grit&eacute; de vos Donn&eacute;es. A ce titre et conform&eacute;ment aux recommandations
                de la CNIL, nous nous assurons par ailleurs que notre prestataire a mis en &oelig;uvre des mesures et/ou
                install&eacute; des dispositifs pour se pr&eacute;munir des attaques par injection de code SQL,
                scripts&hellip; afin de nous pr&eacute;munir contre tout acc&egrave;s non autoris&eacute;, toute utilisation
                impropre, toute alt&eacute;ration, toute destruction malveillante ou accidentelle et toute perte
                involontaire de donn&eacute;es.</span></p>
        <p class="c4"><span class="c7 c6">Nous nous engageons &agrave; maintenir vos Donn&eacute;es confidentielles et
                &agrave; ne les partager que dans les circonstances susvis&eacute;es. A ce titre, nous garantissons que vos
                Donn&eacute;es ne soient pas communiqu&eacute;es &agrave; des institutions ou &agrave; des autorit&eacute;s
                gouvernementales, hormis dans les cas pr&eacute;vus par les dispositions de la loi n&deg;78-17 du 6 janvier
                1978 relative &agrave; l&rsquo;informatique, aux fichiers et aux libert&eacute;s telle que modifi&eacute;e
                en 2004 et &agrave; des fins notamment de r&eacute;pondre aux poursuites judiciaires de toute nature, aux
                ordonnances des tribunaux, &agrave; toute proc&eacute;dures juridiques ou mesures d&rsquo;application de la
                loi r&eacute;clam&eacute;es par les autorit&eacute;s comp&eacute;tentes.</span></p>
        <p class="c4"><span class="c7 c6">&nbsp;</span></p>
        <h3 class="c1" id="h.x8tiovj9tbc"><span class="c7 c15">VII. NOTIFICATION DES CHANGEMENTS</span></h3>
        <p class="c4"><span class="c7 c6">La cr&eacute;ation de nouveaux services sur notre Application peut impliquer la
                modification de notre Charte que nous notifierons dans cette rubrique.</span></p>
        <p class="c4"><span class="c6">Dans ce cas, la date de mise &agrave; jour de la Charte sera actualis&eacute;e et
                indiquera le jour o&ugrave; les modifications ont &eacute;t&eacute; effectu&eacute;es.</span></p>
    </body>
    
    </html>
    `
    };

    return <div dangerouslySetInnerHTML={htmlContent} />;
};

export default PrivacyPolicy;

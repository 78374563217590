import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import Home from './screens/Home';
import AuthDeezer from './screens/AuthDeezer';
import AuthApple from './screens/AuthApple';
import PrivacyPolicy from './screens/PrivacyPolicy';
import TermsOfUse from './screens/TermsOfUse';
import ScrollToTop from './components/ScrollToTop';

const fr = ["fr-FR", "fr", "fr-fr", "fr-be", "fr-BE", "ca", "fr-ca", "fr-CA", "fr-CH", "fr-ch", "fr-LU", "fr-lu", "lu", "be", "ch"].includes(navigator.language);

const App = () => (
    <Router>
        <ScrollToTop />
        <Switch>
            <Route exact path={fr ? '/fr' : '/'} component={Home} />
            <Route path="/authDeezer" component={AuthDeezer} />
            <Route path="/authApple" component={AuthApple} />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/terms" component={TermsOfUse} />
            <Redirect to={fr ? '/fr' : '/'} />
        </Switch>
    </Router>
);

export default App;

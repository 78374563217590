import React from 'react';

const TermsOfUse = () => {
    const htmlContent = {
        __html: `
        <html>

        <head>
            <meta content="text/html; charset=UTF-8" http-equiv="content-type">
            <style type="text/css">
                ul.lst-kix_z0yfmewjt5u8-1 {
                    list-style-type: none
                }
                body {
                    padding: 40px; /* Augmente le padding autour du contenu */
                    background-color: #f4f4f4;
                    max-width: 100%; /* S'assure que le body ne dépasse pas les dimensions de l'écran */
                    word-wrap: break-word; /* Empêche le texte de déborder */
                    box-sizing: border-box; /* Inclut le padding dans la largeur totale */
                }
        
        
                ol {
                    margin: 0;
                    padding: 0
                }
        
                table td,
                table th {
                    padding: 0
                }
        
                .c6 {
                    color: #333333;
                    font-weight: 400;
                    text-decoration: none;
                    vertical-align: baseline;
                    font-size: 18pt;
                    font-family: "Arial";
                    font-style: normal
                }
        
                .c20 {
                    -webkit-text-decoration-skip: none;
                    font-weight: 700;
                    text-decoration: underline;
                    vertical-align: baseline;
                    text-decoration-skip-ink: none;
                    font-family: "Arial";
                    font-style: normal
                }
        
                .c19 {
                    color: #333333;
                    font-weight: 400;
                    text-decoration: none;
                    vertical-align: baseline;
                    font-size: 11pt;
                    font-family: "Arial";
                    font-style: normal
                }
        
                .c1 {
                    color: #333333;
                    font-weight: 400;
                    text-decoration: none;
                    vertical-align: baseline;
                    font-size: 10.5pt;
                    font-family: "Arial";
                    font-style: normal
                }
        
                .c24 {
                    color: #343434;
                    font-weight: 700;
                    text-decoration: none;
                    vertical-align: baseline;
                    font-size: 13.5pt;
                    font-family: "Arial";
                    font-style: normal
                }
        
                .c8 {
                    color: #000000;
                    font-weight: 400;
                    text-decoration: none;
                    vertical-align: baseline;
                    font-size: 11pt;
                    font-family: "Arial";
                    font-style: normal
                }
        
                .c0 {
                    color: #333333;
                    font-weight: 700;
                    text-decoration: none;
                    vertical-align: baseline;
                    font-size: 22.5pt;
                    font-family: "Arial";
                    font-style: normal
                }
        
                .c5 {
                    padding-top: 0pt;
                    padding-bottom: 0pt;
                    line-height: 1.15;
                    orphans: 2;
                    widows: 2;
                    text-align: left;
                    height: 11pt
                }
        
                .c23 {
                    color: #333333;
                    font-weight: 400;
                    text-decoration: none;
                    vertical-align: baseline;
                    font-size: 14pt;
                    font-family: "Arial";
                    font-style: italic
                }
        
                .c9 {
                    color: #333333;
                    font-weight: 400;
                    text-decoration: none;
                    vertical-align: baseline;
                    font-size: 13.5pt;
                    font-family: "Arial";
                    font-style: normal
                }
        
                .c14 {
                    padding-top: 0pt;
                    padding-bottom: 0pt;
                    line-height: 1.15;
                    orphans: 2;
                    widows: 2;
                    text-align: justify
                }
        
                .c2 {
                    padding-top: 0pt;
                    padding-bottom: 8pt;
                    line-height: 1.15;
                    orphans: 2;
                    widows: 2;
                    text-align: justify
                }
        
                .c4 {
                    padding-top: 15pt;
                    padding-bottom: 8pt;
                    line-height: 1.1;
                    orphans: 2;
                    widows: 2;
                    text-align: justify
                }
        
                .c7 {
                    padding-top: 8pt;
                    padding-bottom: 8pt;
                    line-height: 1.1;
                    orphans: 2;
                    widows: 2;
                    text-align: justify
                }
        
                .c21 {
                    text-decoration: none;
                    vertical-align: baseline;
                    font-size: 27pt;
                    font-family: "Arial";
                    font-style: normal
                }
        
                .c25 {
                    text-decoration: none;
                    vertical-align: baseline;
                    font-family: "Arial";
                    font-style: normal
                }
        
                .c17 {
                    background-color: #ffffff;
                    max-width: 451.4pt;
                    padding: 72pt 72pt 72pt 72pt
                }
        
                .c10 {
                    font-size: 10.5pt;
                    color: #333333;
                    font-weight: 700
                }
        
                .c18 {
                    padding: 0;
                    margin: 0
                }
        
                .c3 {
                    margin-left: 36pt;
                    padding-left: 0pt
                }
        
                .c13 {
                    color: #333333;
                    font-size: 13.5pt
                }
        
                .c16 {
                    color: #333333;
                    font-weight: 700
                }
        
                .c11 {
                    color: #333333;
                    font-size: 10.5pt
                }
        
                .c15 {
                    height: 20pt
                }
        
                .c22 {
                    background-color: #ffff00
                }
        
                .c12 {
                    height: 11pt
                }
        
                .c26 {
                    color: #333333
                }
        
                .title {
                    padding-top: 0pt;
                    color: #000000;
                    font-size: 26pt;
                    padding-bottom: 3pt;
                    font-family: "Arial";
                    line-height: 1.15;
                    page-break-after: avoid;
                    orphans: 2;
                    widows: 2;
                    text-align: left
                }
        
                .subtitle {
                    padding-top: 0pt;
                    color: #666666;
                    font-size: 15pt;
                    padding-bottom: 16pt;
                    font-family: "Arial";
                    line-height: 1.15;
                    page-break-after: avoid;
                    orphans: 2;
                    widows: 2;
                    text-align: left
                }
        
                li {
                    color: #000000;
                    font-size: 11pt;
                    font-family: "Arial"
                }
        
                p {
                    margin: 0;
                    color: #000000;
                    font-size: 11pt;
                    font-family: "Arial"
                }
        
                h1 {
                    padding-top: 20pt;
                    color: #000000;
                    font-size: 20pt;
                    padding-bottom: 6pt;
                    font-family: "Arial";
                    line-height: 1.15;
                    page-break-after: avoid;
                    orphans: 2;
                    widows: 2;
                    text-align: left
                }
        
                h2 {
                    padding-top: 18pt;
                    color: #000000;
                    font-size: 16pt;
                    padding-bottom: 6pt;
                    font-family: "Arial";
                    line-height: 1.15;
                    page-break-after: avoid;
                    orphans: 2;
                    widows: 2;
                    text-align: left
                }
        
                h3 {
                    padding-top: 16pt;
                    color: #434343;
                    font-size: 14pt;
                    padding-bottom: 4pt;
                    font-family: "Arial";
                    line-height: 1.15;
                    page-break-after: avoid;
                    orphans: 2;
                    widows: 2;
                    text-align: left
                }
        
                h4 {
                    padding-top: 14pt;
                    color: #666666;
                    font-size: 12pt;
                    padding-bottom: 4pt;
                    font-family: "Arial";
                    line-height: 1.15;
                    page-break-after: avoid;
                    orphans: 2;
                    widows: 2;
                    text-align: left
                }
        
                h5 {
                    padding-top: 12pt;
                    color: #666666;
                    font-size: 11pt;
                    padding-bottom: 4pt;
                    font-family: "Arial";
                    line-height: 1.15;
                    page-break-after: avoid;
                    orphans: 2;
                    widows: 2;
                    text-align: left
                }
        
                h6 {
                    padding-top: 12pt;
                    color: #666666;
                    font-size: 11pt;
                    padding-bottom: 4pt;
                    font-family: "Arial";
                    line-height: 1.15;
                    page-break-after: avoid;
                    font-style: italic;
                    orphans: 2;
                    widows: 2;
                    text-align: left
                }
            </style>
        </head>
        
        <body class="c17 doc-content">
            <div>
                <p class="c5"><span class="c8"></span></p>
            </div>
            <p class="c2"><span class="c16 c21">Terms of Service</span></p>
            <p class="c2"><span class="c23">French version below</span></p>
            <p class="c2"><span class="c1">Updated January 4, 2022</span></p>
            <p class="c2"><span class="c1">I. PREAMBLE</span></p>
            <p class="c2"><span class="c1">DATELY (&quot;DATELY&quot;) is a simplified joint stock company with a capital of
                    1,000 euros registered in the Paris Trade and Companies Register under number 837 545 086 and whose
                    registered office is at 29, rue Henri Barbusse 91170 Viry-Ch&acirc;tillon, represented by its Chief
                    Executive Officer Patrick Berczelly (contact@turn-up.app).</span></p>
            <p class="c2"><span class="c1">DATELY&#39;s purpose is the development and publishing of the &quot;TURN UP&quot;
                    Mobile App downloadable on the &quot;Apple App Store&quot; and the &quot;Google Play Store&quot; (&quot;the
                    Application&quot;) and more generally, the provision of services related to it (the &quot;Services&quot;) in
                    order to allow its members to exchange and discuss with other members (the &quot;User (s)&quot;) based on
                    their music tastes.</span></p>
            <p class="c2"><span class="c1">The use of DATELY&#39;s Services and in particular of its Application is governed by
                    the present general conditions of use (the &quot;Terms of Use&quot;) which are intended to define the
                    rights, obligations and responsibilities of the Users of the Application. and DATELY.</span></p>
            <p class="c2"><span class="c1">By using the DATELY Services and in particular its Application, any User of the
                    Application agrees to be bound by the Terms of Use.</span></p>
            <p class="c2"><span class="c1">A User who does not accept the Terms of Use, can not continue to use and must cease
                    all use of the Services of DATELY and in particular of its Application, without prejudice to the respect of
                    the obligations having vocation to continue at the end of this use.</span></p>
            <p class="c2"><span class="c1">By connecting to the Application and using the Services, the User is deemed to have
                    accepted the Terms of Use.</span></p>
            <p class="c2"><span class="c1">The General Conditions of Use may be updated at any time by DATELY without notice or
                    obligation to give reasons for its decision. The User is therefore invited to regularly check it to be
                    informed of any changes.</span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <p class="c2"><span class="c10 c25">Definitions of terms</span></p>
            <p class="c2"><span class="c1">Each of the terms mentioned below in the Terms of Use will have the following
                    meaning:</span></p>
            <p class="c2"><span class="c1">&quot;Application&quot; means the mobile application marketed under the name
                    &quot;TURN UP&quot; created and published by DATELY running on iOS and Android allowing access to User
                    Services.</span></p>
            <p class="c2"><span class="c1">&quot;Terms of Use&quot; means these Terms of Use</span></p>
            <p class="c2"><span class="c1">&quot;Personal Account&quot; means the account of the User allowing him to access the
                    Services of the Application.</span></p>
            <p class="c2"><span class="c1">&quot;Content (s)&quot; means without this enumeration being exhaustive of any
                    element, content and information, including trademarks, names, logos, texts, images, photographs, music,
                    videos, drawings, models, software , databases, etc., published on the Application by a User.</span></p>
            <p class="c2"><span class="c1">&quot;Chat&quot; means the online service made available to Users on the Application
                    that allows them to instantly exchange and communicate with one or more other Users and / or with a member
                    of the DATELY team.</span></p>
            <p class="c2"><span class="c1">&quot;Message&quot; means a message sent by User to another User via Chat.</span></p>
            <p class="c2"><span class="c1">&quot;Parties&quot; means together DATELY and a User.</span></p>
            <p class="c2"><span class="c1">&quot;Services&quot; means the services provided by DATELY to Users namely: (i) a
                    service linking Users and (ii) a private chat between Users.</span></p>
            <p class="c2"><span class="c1">&quot;User (s)&quot; means any person who has opened a personal account on the
                    Application and uses the Services.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">II. ACCESS TO SERVICES</span></p>
            <p class="c2"><span class="c1">1. Eligibility to use the Services</span></p>
            <p class="c2"><span class="c1">Access to the Services is only allowed to adults. Thus, by connecting to the
                    Application and creating a Personal Account, the User acknowledges that he is at least eighteen years old
                    and undertakes to guarantee his full legal capacity to conclude a contract with DATELY.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">2. Connecting to the Application</span></p>
            <p class="c2"><span class="c1">The connection of the User to the Application is made through his Facebook, Apple,
                    Google or Spotify account or through an account linked to his phone number. The User is informed that by
                    agreeing to connect to the Application via Facebook, Apple, Google or Spotify, he authorizes DATELY to use
                    certain information from his account such as the information available on his profile.</span></p>
            <p class="c2"><span class="c1">The User is responsible for maintaining the confidentiality of his Facebook, Apple,
                    Google or Spotify credentials. The User undertakes, therefore, to inform DATELY of any fraudulent use by a
                    third party of his account and this, as soon as he becomes aware of it. It recognizes, as such, the right
                    for DATELY to take all appropriate measures in such a case and also acknowledges being solely responsible
                    for the use of its identifiers by third parties or actions made by such third parties fraudulent or not
                    .</span></p>
            <p class="c2"><span class="c1">The User acknowledges and agrees that DATELY may at any time suspend or terminate the
                    connection to the Application in the event that the content of the Account would reveal publications
                    contrary to the laws and regulations in force, as well as the provisions of the Terms of Service.</span></p>
            <p class="c2"><span class="c1">Upon registration, the User agrees to these Terms of Use and the Privacy Policy and
                    agrees to comply with its obligations.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">3. Access to Services</span></p>
            <p class="c2"><span class="c1">Access to the Services is subject to the opening by the User of a Personal Account
                    whose creation is free. The information necessary to open the Personal Account is collected from the User
                    who certifies that this information is accurate, truthful and consistent with reality and expressly
                    guarantees not to impersonate a third party.</span></p>
            <p class="c2"><span class="c1">In the event that the User impersonates a third party, the latter acknowledges that
                    DATELY may immediately, without notice or compensation, suspend or close the User&#39;s Personal
                    Account.</span></p>
            <p class="c2"><span class="c1">In no event shall DATELY be held liable in the event of the usurpation of the
                    identity of a User. The Parties acknowledge that any access and / or action performed from the Personal
                    Account of a User will be presumed to be made by this User to the extent that DATELY is not obliged and does
                    not have the technical means to to ensure the identity of the persons having access to the
                    Application.</span></p>
            <p class="c2"><span class="c1">The User has the option of modifying the information related to his profile provided
                    that it is not detrimental to the rights of third parties. The User is informed that in the event that such
                    information proves to be threatening, vulgar, obscene, defamatory, discriminatory, hateful, or if it
                    infringes a third party&#39;s intellectual property right or the reputation of DATELY, DATELY would have the
                    right to request the User immediate modification of this type of information and in case of refusal on his
                    part, to suspend or close his Personal Account.</span></p>
            <p class="c2"><span class="c1">The User also undertakes not to open and / or multiply the &quot;ghost&quot;
                    accounts, otherwise inactive, on the Application and acknowledges that DATELY, from the discovery of
                    accounts of this type, reserves the right to delete them without delay.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">III. DESCRIPTION OF SERVICES</span></p>
            <p class="c2"><span class="c1">The Services offered on the Application allow all Users having a Personal Account to
                    be put in contact with one or more other Users. The Application provides Users with the opportunity to make
                    contact with each other in order to establish a virtual or real contact, as they see fit. Each User is free
                    to discuss and establish relations with other Users in compliance with these Terms of Use.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">IV. OBLIGATIONS OF THE USER</span></p>
            <p class="c2"><span class="c1">1. Interaction (s) with another User</span></p>
            <p class="c2"><span class="c1">The User agrees to:</span></p>
            <ul class="c18 lst-kix_tsy1qrazr2sh-0 start">
                <li class="c2 c3 li-bullet-0"><span class="c1">adopt a correct and respectful attitude towards other
                        Users</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">be honest in his statements</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">respect intellectual or industrial property rights</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">not upload photos of a third party</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">not using any software, application, interface or help from a
                        third party to interfere in making contact with other Users of the Application</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">remain courteous with other Users</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">not to affect the Services or the Application</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">not to publish on the Application pornographic content and photos
                        or to undermine modesty and human dignity</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">do not disturb public order</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">not to commit the offense of defamation and, more broadly, not to
                        use offensive language, particularly racial or discriminatory</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">respect the laws and rules in force</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">not to incite violence or to condone terrorism, war crimes or
                        crimes against humanity</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">do not use the Application for the purpose of organizing
                        gatherings and / or events.</span></li>
            </ul>
            <p class="c2"><span class="c1">The User is thus fully responsible for the information and the Content that he
                    communicates to another User and the subsequent use that may be made of it. DATELY recommends to the User
                    not to communicate confidential or sensitive information and contents such as his bank details or the
                    banking data of others to another User. Without prejudice to the preceding stipulations, DATELY can not be
                    responsible for the use made of this information by other Users.</span></p>
            <p class="c2"><span class="c1">In case of violation of the aforementioned provisions, the User is informed that
                    DATELY has the option to terminate the User&#39;s Personal Account without delay and notice and to make his
                    access to the Application and the Services unavailable.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">2. Information and Content broadcast</span></p>
            <p class="c2"><span class="c1">The User is free to broadcast the content of his choice on the Application provided
                    that this Content is not contrary to the legal and regulatory provisions in force, nor does it affect the
                    rights of a third party.</span></p>
            <p class="c2"><span class="c1">The User acknowledges that he is responsible for any Content published on the
                    Application and thus undertakes not to broadcast Content that contravenes the laws and regulations in force,
                    or that infringes the rights of third parties, or that is vulgar, discriminating or offensive.</span></p>
            <p class="c2"><span class="c1">The User warrants, in particular, that the published Content is not related to the
                    repression of the apology for crimes against humanity, incitement to racial hatred, hatred towards persons
                    because of their gender, orientation, sexual identity or disability, as well as child pornography,
                    incitement to violence, and does not undermine human dignity.</span></p>
            <p class="c2"><span class="c1">The User further acknowledges that the published Content does not infringe the rights
                    of third parties and that it is not, in particular, illegal and / or infringing content and guarantees to
                    hold all the intellectual property rights therein. thereon.</span></p>
            <p class="c2"><span class="c1">The User agrees to use the Application only for personal purposes. The network must
                    not be exploited for commercial, promotional, electoral or recruitment purposes. The sending of a Message to
                    marketing and / or commercial purpose and / or newsletters via the Chat Application that includes, including
                    but not limited to, content, indications or hypertext links to sites or supports of third companies , and in
                    particular competitors of DATELY, is formally prohibited.</span></p>
            <p class="c2"><span class="c1">The User further acknowledges that any Content notified by a third party to the
                    DATELY teams, in the form required by applicable legislation, which is manifestly unlawful, will be promptly
                    withdrawn by DATELY teams whose aim is to effect of making said Content inaccessible without the need to
                    inform the User in advance.</span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <p class="c2"><span class="c1">3. Real meetings</span></p>
            <p class="c2"><span class="c1">DATELY warns Users who want to make &quot;real&quot; encounters. It is entirely up to
                    Users to verify the identity of the people with whom they organize these meetings. The conduct of these
                    &quot;real&quot; meetings is organized independently of DATELY, under the sole responsibility of the Users.
                    The responsibility of DATELY can not be sought for the acts and problems, whatever their nature, committed
                    or provoked by the Users and / or former User during the events taking place following the use of the
                    Application. In this respect, Chat voluntarily presents the advantage, again in order to preserve the peace
                    and security of its Users, to allow Users to decide who will be allowed to see their profiles and to discuss
                    with them (via the &quot;Block&quot; and &quot;Report&quot; features).</span></p>
            <p class="c2"><span class="c1">It is strongly recommended that you do not disclose information that does not appear
                    on the Application Profile such as phone number, address, or last name. DATELY strongly advises, for
                    security reasons, to organize the actual meetings only in public places with a lot of traffic or to warn a
                    relative and to reveal their contact details only after a reasonable delay.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Equipment</span></p>
            <p class="c2"><span class="c1">The equipment (hardware or software) necessary to access the Services and all other
                    related costs, including telecommunication costs are the sole responsibility of the User. The User is solely
                    responsible for the material used in the Service.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">V. ROLE AND RESPONSIBILITIES OF DATELY</span></p>
            <p class="c2"><span class="c1">1. Interactions between Users</span></p>
            <p class="c2"><span class="c1">DATELY is not responsible for the conduct of a User towards another User, or the
                    words held or content disseminated by a User on the Chat or during the physical meeting between
                    Users.</span></p>
            <p class="c2"><span class="c1">DATELY does not carry out any verification of the situation of a User, and more
                    particularly of his legal situation, prior to his use of the Services. It is therefore incumbent upon each
                    User to inquire about the User&#39;s situation and to notify DATELY, where appropriate, who reserves the
                    right to alert the competent administrative or judicial services.</span></p>
            <p class="c2"><span class="c1">DATELY also disclaims all liability for all information, in particular financial
                    information and more generally any so-called sensitive information, which may be transmitted by a User to
                    another User in the event of fraudulent or illicit use by the party. receiving national.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <p class="c2"><span class="c1">2. Communication with the User</span></p>
            <p class="c2"><span class="c1">The User may receive push notifications via the Application in order to be notified
                    of important events.</span></p>
            <p class="c2"><span class="c1">The User may adjust his preferences for push notifications from his phone.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">3. Information and Content disseminated by Users</span></p>
            <p class="c2"><span class="c1">The User is solely responsible for his actions and the data he publishes via the
                    Application.</span></p>
            <p class="c2"><span class="c1">DATELY retains the User&#39;s data for the duration necessary for the use of the
                    Personal Account.</span></p>
            <p class="c2"><span class="c1">DATELY has the quality of host of the information and Contents diffused at their
                    discretion by the Users on the Application and is, consequently, subjected to the regime of the weakened
                    responsibility envisaged by the articles 6.I.2 and following of the law n 2004-575 of June 2, 2004.</span>
            </p>
            <p class="c2"><span class="c1">DATELY cannot, as such, be held liable for the Information and Content disseminated
                    through the Application to the extent that it has not exercised control over the Information and Content,
                    and that it has not an effective knowledge of their manifestly unlawful character.</span></p>
            <p class="c2"><span class="c1">DATELY does not exercise any moderation of the content published by the Users and
                    shared via the Application: in particular, no photo, text and content to which the hypertext links published
                    by Users are redirected are checked by the DATELY teams. The User may report unlawful content that violates
                    French law or these Terms of Use by means of the moderation tools provided by the Application.</span></p>
            <p class="c2"><span class="c1">DATELY reserves the right, in the event that too many illicit contents come from the
                    same User, to delete his Personal Account without delay and to suspend his connection to the
                    Application.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">4. Availability and operation of the Application and Services</span></p>
            <p class="c2"><span class="c1">DATELY makes its best efforts to maintain the Application accessible to Users,
                    subject to (i) cases of force majeure as recognized by French courts or (ii) by Users. DATELY may, however,
                    be obliged to suspend without notice and momentarily access to the Application due to technical problems,
                    but also for reasons of maintenance / repair / modification of the Application or Services. As such, DATELY
                    does not guarantee uninterrupted access to the Application and the Services.</span></p>
            <p class="c2"><span class="c1">DATELY makes, besides, its best efforts to ensure the preservation of the security of
                    the Application. However, the Application may occasionally present errors and / or bugs likely to cause
                    malfunctions of the Services or loss of data on the Application for which DATELY cannot be responsible under
                    any circumstances because of the useful precautions put in place. by DATELY and being specified that each
                    User must save a copy of the data and can not claim any compensation in this respect.</span></p>
            <p class="c2"><span class="c1">DATELY can not be held responsible for any malfunction of the terminal of the User or
                    compatibility problem between the Application and the terminal.</span></p>
            <p class="c2"><span class="c1">DATELY is not responsible for any breakdowns, interruptions or malfunctions of the
                    services of the internet service provider of the User, as well as any cause external to DATELY that could
                    interrupt or damage access to the Services.</span></p>
            <p class="c2"><span class="c1">DATELY also excludes any liability for any damage caused by the Application to the
                    User&#39;s operating system, interruptions or disruption of the Services or hardware or software failures of
                    the Application or any damage suffered by the transmission of a virus. computer, a Trojan horse or any other
                    form of programming likely to damage or destroy the functionality of a mobile phone or tablet or hinder its
                    proper functioning.</span></p>
            <p class="c2"><span class="c1">DATELY also wants to prevent the User against any exceptional risk of receiving
                    unwanted messages or messages allegedly from DATELY. The User must be attentive and vigilant in order, if
                    these assumptions are realized, to warn DATELY.</span></p>
            <p class="c2"><span class="c1">DATELY can not be held liable for indirect damages, criminal offenses of Users,
                    commercial loss, loss of income, loss of profits, loss of profits, loss of contracts, loss of customers,
                    loss of opportunity, loss of profits, loss of data. , data breach, damage to image or reputation or
                    non-pecuniary damage.</span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&nbsp;5. Purchasing</span></p>
            <p class="c2"><span class="c1">In-app purchases within the Application (IAP). At any time, DATELY may offer products
                    and services for purchase (&quot;in-app purchases&quot;) through iTunes or Google Play (each a
                    &quot;Software Store&quot;). If you choose to make in-app purchases, you will be asked to enter your account
                    information on the Software Store (&quot;your IAP Account&quot;), and internal purchases will be billed to
                    your Account. IAP are made in accordance with the terms and conditions communicated to you at the time of
                    purchase, and the terms and conditions of in-app purchases that apply to your IPA Account. Some Software
                    Stores may charge you a sales tax depending on where you live. If you purchase an auto-renewing subscription
                    through an in-app purchase, your IAP Account will be charged for the subscription until you cancel it. After
                    your commitment for an initial subscription period, and then after any subsequent subscription period, your
                    subscription will automatically renew for an additional equivalent period, at the price you approved at the
                    time of subscription. If you do not want your subscription to renew automatically, or if you want to change
                    or renew your subscription, you will need to sign in to your IAP account and follow the instructions to
                    cancel your subscription, even if you have deleted your account from us or if you have deleted the App from
                    your device. It is not enough to delete your account on TURN UP or remove the App from your device to cancel
                    your subscription. DATELY will keep all funds debited from your IAP account until you cancel your
                    subscription through your IAP account.</span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <p class="c2"><span class="c1">VI. INTELLECTUAL PROPERTY</span></p>
            <p class="c2"><span class="c1">1. Property of the Application</span></p>
            <p class="c2"><span class="c1">The names of the Services, the trademarks, logos, graphics, photographs, animations,
                    videos and texts contained on the Application and in the Services are the property of DATELY, and may not be
                    reproduced, used or represented without the express authorization of the DATELY, under penalty of legal
                    proceedings.</span></p>
            <p class="c2"><span class="c1">The rights of use granted to the User are reserved for private and personal use
                    within the framework and for the duration of the subscription to the Services. Any other use by the User is
                    prohibited.</span></p>
            <p class="c2"><span class="c1">The User agrees not to modify, copy, reproduce, download, broadcast, transmit,
                    commercially exploit and / or distribute in any way the Services or the computer codes of the components of
                    the Services and the Application. Without the express and prior authorization of DATELY, this would
                    constitute an infringement punishable by the provisions of the Code of Intellectual Property.</span></p>
            <p class="c2"><span class="c1">All of these elements are made available to the User who undertakes to respect them,
                    not to modify or extract them, or to use them prohibited by law and the regulations in force.</span></p>
            <p class="c2"><span class="c1">DATELY grants to the User, a limited, non-transferable and non-exclusive license on
                    the Application for the purpose of allowing him to download and install a copy of the Application on his
                    mobile devices and / or tablets for the purpose of strictly personal uses.</span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <p class="c2"><span class="c1">2. Ownership and License of User Content</span></p>
            <p class="c2"><span class="c1">Any element, content and information, including trademarks, names, logos, texts,
                    visuals, images, photographs, music, videos, drawings, models, software, databases, etc., provided by the
                    User, for placing available and distributed via DATELY&#39;s Services and in particular its Application,
                    remain the property of said User.</span></p>
            <p class="c2"><span class="c1">Without prejudice to the foregoing, the User grants to DATELY non-exclusive, free and
                    worldwide the right to reproduce and distribute the Content published in whole or in part by the User on the
                    Application.</span></p>
            <p class="c2"><span class="c1">The right of reproduction is hereby granted to DATELY for the purposes of: (i)
                    performing the Services and, in particular, allowing the User to view the Content posted by other Users on
                    the Application and (ii) for the purposes of communication, promotion, and / or advertising of the Services
                    and / or the Application and / or DATELY, in any format, on any medium, and by any means, known or unknown
                    to date, and in particular but not exclusively, on all media and networks, on partner sites of third
                    parties.</span></p>
            <p class="c2"><span class="c1">The User acknowledges and expressly agrees that, when DATELY distributes the Content
                    on social networks, only the general conditions of use of the social network will be exclusively
                    applicable.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <p class="c2"><span class="c1">VII. LINKS TO OTHER APPLICATIONS</span></p>
            <p class="c2"><span class="c1">The Application may contain links to external applications that may be of interest to
                    the User.</span></p>
            <p class="c2"><span class="c1">The responsibility of DATELY can not be sought or committed as to the content of any
                    of these third-party applications, to which the User would have had access by means of these links.</span>
            </p>
            <p class="c2"><span class="c1">In addition, DATELY has no way of controlling the content of these third-party
                    applications, which remain completely independent of DATELY. Moreover, the existence of a link between
                    DATELY and a third-party Application does not mean that DATELY approves in any respect whatsoever the
                    content of this Application and all the more the use that could be made of it.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">VIII. TERMINATION / SUSPENSION</span></p>
            <p class="c2"><span class="c1">DATELY reserves the right to terminate the Terms of Use without notice and without
                    special reason for reasons of convenience. Similarly, the User may terminate the Terms of Use without notice
                    and without special reason for reasons of convenience by clicking on the delete button Personal Account
                    found in the &quot;Profile&quot; section of the Application.</span></p>
            <p class="c2"><span class="c1">DATELY reserves the right to terminate the Terms of Use without notice and without
                    any special reason and / or to suspend a User from access to its Services and in particular to its
                    Application and / or to delete and / or suspend Content made available through its Services and in
                    particular its Application by a User, at any time, without notice and without notice of the User in case
                    of:</span></p>
            <ul class="c18 lst-kix_z0yfmewjt5u8-0 start">
                <li class="c2 c3 li-bullet-0"><span class="c1">substantial violation of the Terms of Use</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">publications of Defamatory, Denigrating, Abusive, Obscene,
                        Violent, Racist Content</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">concealed, fraudulent or illegal activities through its Services
                        and in particular its Application</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">more generally, in case of violation of applicable laws and
                        regulations or the rights of third parties.</span></li>
            </ul>
            <p class="c2"><span class="c1">From the closure of his account and / or the removal of access to the Application,
                    the User acknowledges that all of its published Content will no longer be accessible through the
                    Application.</span></p>
            <p class="c2"><span class="c1">In the event of termination of the Terms of Use, the stipulations of these which
                    should reasonably continue after the termination thereof, will remain in effect.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">IX. DURATION OF THE TERMS OF USE</span></p>
            <p class="c2"><span class="c1">The User is subject to compliance with the Terms and Conditions of Use from the
                    opening and closing of his Personal Account.</span></p>
            <p class="c2"><span class="c1">The applicable General Conditions of Use are those in force on the day of use of the
                    Application by the User.</span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">X. MODIFICATION</span></p>
            <p class="c2"><span class="c1">DATELY reserves the right, at any time and unilaterally, to supplement or modify
                    these Terms of Use as it deems necessary, or to terminate the Services.</span></p>
            <p class="c2"><span class="c1">These modifications will be indicated by any useful means and in particular by the
                    publication of a mention of update with the date of update, within the Terms and Conditions of Use.</span>
            </p>
            <p class="c2"><span class="c1">A User who does not accept the modifications of the General Conditions of Use, will
                    not be able to continue and will have to stop any use of the Services and in particular of the Application,
                    without prejudice of the respect of the obligations having vocation to continue at the end of this use
                    .</span></p>
            <p class="c2"><span class="c1">It is the responsibility of the User to carefully read the Terms of Use when updating
                    them and to be fully aware of the changes caused by the latter. The fact that the User continues to use the
                    Application Services after the update of the Terms of Use implies acceptance without reservation on his part
                    to the changes to the Terms of Use.</span></p>
            <p class="c2"><span class="c1">In case of disagreement with the modifications of the General Conditions of Use, the
                    User may terminate the Services and terminate his Account in accordance with Article VIII.</span></p>
            <p class="c2"><span class="c1">The new Terms and Conditions of Use will apply immediately for Users.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">XI. AUTONOMY</span></p>
            <p class="c2"><span class="c1">In the event that one of the stipulations of the present Terms of Use is declared
                    null or inapplicable by the effect of a law of a regulation or a jurisdictional decision emanating from a
                    competent court, the other stipulations retain their validity and strength, save to imagine that these Terms
                    and Conditions of Use would be distorted or that this entails a significant imbalance of the obligations
                    incumbent on the parties.</span></p>
            <p class="c2"><span class="c1">In this hypothesis, DATELY will replace as soon as possible a valid and satisfactory
                    stipulation in place and instead of the stipulation whose validity is affected.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">XII. APPLICABLE LAW</span></p>
            <p class="c2"><span class="c1">These Terms of Use are subject to French law.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">XIII. COMPETENT JURISDICTIONS</span></p>
            <p class="c2"><span class="c1">In case of dispute, the User will first contact the DATELY teams to find an amicable
                    solution. DATELY will consider reasonable requests for dispute resolution through alternative procedures
                    such as mediation or arbitration or through collaborative law.</span></p>
            <p class="c2"><span class="c1">In the absence of resolution of the dispute by means of alternative procedures, any
                    dispute and / or difficulty of interpretation or execution of the general conditions of use will fall within
                    the jurisdiction of the French courts.</span></p>
            <p class="c2"><span class="c1">Neither DATELY nor the User are authorized to disclose information on the contract
                    binding them in case of dispute and each of the parties undertakes to respect a confidentiality clause which
                    obliges it to prefer a confidential negotiation in case of dispute.</span></p>
            <h1 class="c4 c15" id="h.xg64vs9i7sfq"><span class="c21 c16"></span></h1>
            <p class="c5"><span class="c8"></span></p>
            <p class="c5"><span class="c8"></span></p>
            <p class="c5"><span class="c8"></span></p>
            <p class="c5"><span class="c8"></span></p>
            <p class="c5"><span class="c8"></span></p>
            <p class="c5"><span class="c8"></span></p>
            <p class="c5"><span class="c8"></span></p>
            <p class="c5"><span class="c8"></span></p>
            <p class="c5"><span class="c8"></span></p>
            <p class="c5"><span class="c8"></span></p>
            <p class="c5"><span class="c8"></span></p>
            <p class="c5"><span class="c8"></span></p>
            <p class="c5"><span class="c8"></span></p>
            <h1 class="c4" id="h.6mwome46745t"><span class="c21 c16">Conditions G&eacute;n&eacute;rales d&#39;Utilisation</span>
            </h1>
            <p class="c2"><span class="c1">Mise &agrave; jour le 04 janvier 2022</span></p>
            <h3 class="c4" id="h.o1veoc99efc1"><span class="c6">I. PR&Eacute;AMBULE</span></h3>
            <p class="c2"><span class="c11">La soci&eacute;t&eacute; DATELY (&laquo; </span><span class="c10">DATELY</span><span
                    class="c11">&nbsp;&raquo;) est une soci&eacute;t&eacute; par actions simplifi&eacute;e au capital de
                </span><span class="c1">1.000 euros inscrite au registre du Commerce et des Soci&eacute;t&eacute;s de Paris sous
                    le num&eacute;ro 837 545 086 et dont le si&egrave;ge social est &eacute;tabli au 29, rue Henri Barbusse
                    91170 Viry-Ch&acirc;tillon, repr&eacute;sent&eacute;e par son Pr&eacute;sident Patrick Berczelly
                    (contact@turn-up.app).</span></p>
            <p class="c2"><span class="c11">DATELY a pour objet le d&eacute;veloppement et l&rsquo;&eacute;dition de
                    l&rsquo;Application mobile &laquo; TURN UP &raquo; t&eacute;l&eacute;chargeable sur &laquo; l&rsquo;Apple
                    App Store &raquo; et le &laquo; Google Play Store &raquo; (&laquo; l&rsquo; </span><span
                    class="c10">Application</span><span class="c11">&nbsp;&raquo;) et plus g&eacute;n&eacute;ralement, la
                    fourniture de services li&eacute;s &agrave; celle-ci (les &laquo; </span><span
                    class="c10">Services</span><span class="c11">&nbsp;&raquo;) en vue de permettre &agrave; ses membres
                    d&rsquo;&eacute;changer et de discuter avec d&rsquo;autres membres (l&rsquo;/les &laquo; </span><span
                    class="c10">Utilisateur(s)</span><span class="c1">&nbsp;&raquo;), avec la musique en fondement.</span></p>
            <p class="c2"><span class="c11">L&rsquo;utilisation des Services de DATELY et notamment de son Application est
                    r&eacute;gie par les pr&eacute;sentes conditions g&eacute;n&eacute;rales d&rsquo;utilisation (les &laquo;
                </span><span class="c10">Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation</span><span
                    class="c1">&nbsp;&raquo;) qui ont pour objet de d&eacute;finir les droits, obligations et
                    responsabilit&eacute;s des Utilisateurs de l&rsquo;Application et de DATELY.</span></p>
            <p class="c2"><span class="c1">En utilisant les Services de DATELY et notamment son Application, tout Utilisateur de
                    l&rsquo;Application accepte d&rsquo;&ecirc;tre li&eacute; par les Conditions G&eacute;n&eacute;rales
                    d&rsquo;Utilisation.</span></p>
            <p class="c2"><span class="c1">Un Utilisateur qui n&rsquo;accepte pas les Conditions G&eacute;n&eacute;rales
                    d&rsquo;Utilisation, ne peut pas continuer &agrave; utiliser et doit cesser toute utilisation des Services
                    de DATELY et notamment de son Application, sans pr&eacute;judice du respect des obligations ayant vocation
                    &agrave; perdurer &agrave; la cessation de cette utilisation.</span></p>
            <p class="c2"><span class="c1">En se connectant &agrave; l&rsquo;Application et en utilisant les Services,
                    l&rsquo;Utilisateur est r&eacute;put&eacute; avoir accept&eacute; les Conditions G&eacute;n&eacute;rales
                    d&rsquo;Utilisation.</span></p>
            <p class="c2"><span class="c1">Les Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation sont susceptibles
                    d&rsquo;&ecirc;tre mises &agrave; jour &agrave; tout moment par DATELY sans pr&eacute;avis ni obligation de
                    motiver sa d&eacute;cision. L&rsquo;Utilisateur est donc invit&eacute; &agrave; en prendre
                    r&eacute;guli&egrave;rement connaissance afin d&rsquo;&ecirc;tre inform&eacute; de toute &eacute;ventuelle
                    modification.</span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <h2 class="c4" id="h.6gqnepz9iu04"><span class="c0">D&eacute;finitions des termes</span></h2>
            <p class="c2"><span class="c1">Chacun des termes mentionn&eacute;s ci-dessous dans les Conditions
                    G&eacute;n&eacute;rales d&rsquo;Utilisation aura la signification suivante :</span></p>
            <p class="c2"><span class="c11">&laquo; </span><span class="c10">Application</span><span class="c1">&nbsp;&raquo;
                    d&eacute;signe l&rsquo;application mobile commercialis&eacute;e sous le nom de &laquo; TURN UP &raquo;
                    cr&eacute;&eacute;e et &eacute;dit&eacute;e par DATELY fonctionnant sur iOS et Android permettant
                    l&rsquo;acc&egrave;s aux Services aux Utilisateurs.</span></p>
            <p class="c2"><span class="c11">&laquo; </span><span class="c10">Conditions G&eacute;n&eacute;rales
                    d&rsquo;Utilisation </span><span class="c1">&raquo; d&eacute;signe les pr&eacute;sentes Conditions
                    G&eacute;n&eacute;rales d&rsquo;Utilisation</span></p>
            <p class="c2"><span class="c11">&laquo; </span><span class="c10">Compte Personnel</span><span
                    class="c1">&nbsp;&raquo; d&eacute;signe le compte de l&rsquo;Utilisateur lui permettant
                    d&rsquo;acc&eacute;der aux Services de l&rsquo;Application.</span></p>
            <p class="c2"><span class="c11">&laquo; </span><span class="c10">Contenu(s)</span><span class="c1">&nbsp;&raquo;
                    d&eacute;signe(nt) sans que cette &eacute;num&eacute;ration soit limitative tout &eacute;l&eacute;ment,
                    contenu et information, en ce compris les marques, d&eacute;nominations, logos, textes, visuels, images,
                    photographies, musiques, vid&eacute;os, dessins, mod&egrave;les, logiciels, bases de donn&eacute;es, etc.,
                    publi&eacute; sur l&rsquo;Application par un Utilisateur.</span></p>
            <p class="c2"><span class="c11">&laquo; </span><span class="c10">Chat</span><span class="c1">&nbsp;&raquo;
                    d&eacute;signe le service en ligne mis &agrave; la disposition des Utilisateurs sur l&rsquo;Application qui
                    leur permet d&rsquo;&eacute;changer et de communiquer instantan&eacute;ment avec un ou plusieurs autres
                    Utilisateurs et/ou avec un membre de l&rsquo;&eacute;quipe de DATELY.</span></p>
            <p class="c2"><span class="c11">&laquo; </span><span class="c10">Message</span><span class="c1">&nbsp;&raquo;
                    d&eacute;signe un message envoy&eacute; par Utilisateur &agrave; un autre Utilisateur via le Chat.</span>
            </p>
            <p class="c2"><span class="c11">&laquo; </span><span class="c10">Parties</span><span class="c1">&nbsp;&raquo;
                    d&eacute;signe ensemble DATELY et un Utilisateur.</span></p>
            <p class="c2"><span class="c11">&laquo; </span><span class="c10">Services</span><span class="c1">&nbsp;&raquo;
                    d&eacute;signe les services fournis par DATELY aux Utilisateurs : (i) un service de mise en relation entre
                    Utilisateurs et (ii) un chat priv&eacute; entre Utilisateurs.</span></p>
            <p class="c2"><span class="c11">&laquo; </span><span class="c10">Utilisateur(s)</span><span class="c1">&nbsp;&raquo;
                    d&eacute;signe toute personne ayant ouvert un compte personnel sur l&rsquo;Application et utilisant les
                    Services.</span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <h3 class="c4" id="h.tc7blq7v5ik5"><span class="c6">II. ACC&Egrave;S AUX SERVICES</span></h3>
            <h4 class="c7" id="h.g9p4xp123rcw"><span class="c9">1. &Eacute;ligibilit&eacute; d&rsquo;utilisation des
                    Services</span></h4>
            <p class="c2"><span class="c1">L&rsquo;acc&egrave;s aux Services est uniquement autoris&eacute; aux personnes
                    majeures. Ainsi, en se connectant &agrave; l&rsquo;Application et en cr&eacute;ant un Compte Personnel,
                    l&rsquo;Utilisateur reconna&icirc;t &ecirc;tre &acirc;g&eacute; d&rsquo;au moins dix-huit ans et
                    s&rsquo;engage &agrave; garantir sa pleine capacit&eacute; juridique &agrave; conclure un contrat avec
                    DATELY.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h4 class="c7" id="h.mgtqvzz1lxoj"><span class="c9">2. Connexion &agrave; l&rsquo;Application</span></h4>
            <p class="c2"><span class="c1">La connexion de l&rsquo;Utilisateur &agrave; l&rsquo;Application s&rsquo;effectue au
                    moyen de son compte Facebook, Google, Apple ou Spotify ou d&rsquo;un compte li&eacute; &agrave; son
                    num&eacute;ro de t&eacute;l&eacute;phone. L&rsquo;Utilisateur est inform&eacute; qu&rsquo;en acceptant de se
                    connecter &agrave; l&rsquo;Application par l&rsquo;interm&eacute;diaire de Facebook, Google, Apple ou
                    Spotify, il autorise DATELY &agrave; utiliser certaines informations de son compte telles que les
                    informations disponibles sur son profil. En cons&eacute;quence, l&rsquo;Utilisateur doit proc&eacute;der
                    &agrave; la v&eacute;rification de l&rsquo;authenticit&eacute; de ses informations pr&eacute;alablement
                    &agrave; son inscription aux Services.</span></p>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur est seul autoris&eacute; &agrave; se connecter &agrave;
                    l&rsquo;Application au moyen de son Compte Facebook, Google, Apple ou Spotify et il est responsable du
                    maintien de la confidentialit&eacute; de ses identifiants. L&rsquo;Utilisateur s&rsquo;engage, en
                    cons&eacute;quence, &agrave; informer DATELY de toute utilisation frauduleuse par un tiers de son compte et
                    ce, d&egrave;s qu&rsquo;il en a eu connaissance. Il reconna&icirc;t, &agrave; ce titre, le droit pour DATELY
                    de prendre toutes mesures appropri&eacute;es en pareil cas et reconna&icirc;t par ailleurs &ecirc;tre seul
                    responsable de l&#39;utilisation de ses identifiants par des tiers ou des actions faites par ces tiers
                    qu&#39;elles soient frauduleuses ou non.</span></p>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur reconna&icirc;t et accepte que DATELY puisse &agrave; tout
                    moment, suspendre ou cl&ocirc;turer la connexion &agrave; l&rsquo;Application dans l&rsquo;hypoth&egrave;se
                    o&ugrave; le contenu du Compte laisserait appara&icirc;tre des publications contraires aux lois et
                    r&eacute;glementations en vigueur, ainsi qu&rsquo;aux dispositions des Conditions G&eacute;n&eacute;rales
                    d&rsquo;Utilisation.</span></p>
            <p class="c2"><span class="c1">Lors de l&rsquo;inscription, l&rsquo;Utilisateur consent aux pr&eacute;sentes
                    Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation et &agrave; la Charte de confidentialit&eacute; et
                    s&rsquo;engage &agrave; respecter les obligations &agrave; sa charge.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h4 class="c7" id="h.by6oe7ihiz6"><span class="c9">3. Acc&egrave;s aux Services</span></h4>
            <p class="c2"><span class="c1">L&rsquo;acc&egrave;s aux Services est subordonn&eacute; &agrave; l&rsquo;ouverture
                    par l&rsquo;Utilisateur d&rsquo;un Compte Personnel dont la cr&eacute;ation est libre. Les informations
                    n&eacute;cessaires &agrave; l&rsquo;ouverture du Compte Personnel sont indiqu&eacute;es dans
                    l&rsquo;Application par l&rsquo;Utilisateur qui certifie que ces informations sont exactes, sinc&egrave;res
                    et conformes &agrave; la r&eacute;alit&eacute; et garantit express&eacute;ment ne pas usurper
                    l&rsquo;identit&eacute; d&rsquo;un tiers.</span></p>
            <p class="c2"><span class="c1">Dans le cas o&ugrave; l&rsquo;Utilisateur usurperait l&rsquo;identit&eacute;
                    d&rsquo;un tiers, ce dernier reconna&icirc;t que DATELY pourra imm&eacute;diatement, sans pr&eacute;avis, ni
                    indemnit&eacute;, suspendre ou cl&ocirc;turer le Compte Personnel de l&rsquo;Utilisateur.</span></p>
            <p class="c2"><span class="c1">En aucun cas, DATELY ne saurait &ecirc;tre tenue responsable en cas
                    d&rsquo;usurpation de l&rsquo;identit&eacute; d&rsquo;un Utilisateur. Les Parties reconnaissent que tout
                    acc&egrave;s ou/et action effectu&eacute; &agrave; partir du Compte Personnel d&rsquo;un Utilisateur sera
                    pr&eacute;sum&eacute; &ecirc;tre effectu&eacute; par cet Utilisateur dans la mesure o&ugrave; DATELY
                    n&rsquo;a pas pour obligation et ne dispose pas des moyens techniques lui permettant de s&rsquo;assurer de
                    l&rsquo;identit&eacute; des personnes ayant acc&egrave;s &agrave; l&rsquo;Application.</span></p>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur a la facult&eacute; de modifier les informations relatives
                    &agrave; son profil sous r&eacute;serve que celles-ci ne soient pas attentatoires aux droits des tiers.
                    L&rsquo;Utilisateur est inform&eacute; que dans le cas o&ugrave; lesdites informations se
                    r&eacute;v&eacute;leraient mena&ccedil;antes, vulgaires, obsc&egrave;nes, diffamatoires, discriminatoires,
                    haineuses, ou si elles portaient atteinte &agrave; un droit de propri&eacute;t&eacute; intellectuelle
                    d&rsquo;un tiers ou &agrave; la r&eacute;putation de DATELY, DATELY se r&eacute;serverait le droit de
                    requ&eacute;rir aupr&egrave;s de l&rsquo;Utilisateur la modification imm&eacute;diate de ce type
                    d&rsquo;information et en cas de refus de sa part, de suspendre ou de cl&ocirc;turer son Compte
                    Personnel.</span></p>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur s&rsquo;engage &eacute;galement &agrave; ne pas ouvrir et/ou
                    multiplier les comptes &laquo; fant&ocirc;mes &raquo; autrement dit inactifs, sur l&rsquo;Application et
                    reconna&icirc;t que DATELY, &agrave; compter de la d&eacute;couverte de comptes de ce type, se
                    r&eacute;serve le droit de les supprimer sans d&eacute;lai.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h3 class="c4" id="h.tq8n4umvg60w"><span class="c6">III. DESCRIPTION DES SERVICES</span></h3>
            <p class="c2"><span class="c1">Les Services propos&eacute;s sur l&rsquo;Application permettent &agrave; tous les
                    Utilisateurs disposant d&rsquo;un Compte Personnel d&rsquo;&ecirc;tre mis en relation avec un ou plusieurs
                    autres Utilisateurs. L&rsquo;Application fournit aux Utilisateurs l&rsquo;occasion de prendre contact entre
                    eux dans le but d&rsquo;&eacute;tablir un contact virtuel ou r&eacute;el, comme bon leur semble. Chaque
                    Utilisateur est libre de discuter et nouer des relations avec des autres Utilisateurs dans le respect des
                    pr&eacute;sentes Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h3 class="c4" id="h.4yust979yk9z"><span class="c6">IV. OBLIGATIONS DE L&rsquo;UTILISATEUR</span></h3>
            <h4 class="c7" id="h.q7usnofsweap"><span class="c9">1. Interaction(s) avec un autre Utilisateur</span></h4>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur s&rsquo;engage &agrave; :</span></p>
            <ul class="c18 lst-kix_hyjbotnmhwvg-0 start">
                <li class="c2 c3 li-bullet-0"><span class="c1">adopter une attitude correcte et respectueuse vis-&agrave;-vis
                        des autres Utilisateurs</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">&ecirc;tre honn&ecirc;te dans ses d&eacute;clarations</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">respecter les droits de propri&eacute;t&eacute; intellectuelle ou
                        industrielle</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">ne pas mettre en ligne les photos d&rsquo;un tiers</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">ne pas utiliser de logiciel, d&rsquo;application,
                        d&rsquo;interface ou l&rsquo;aide d&rsquo;un tiers afin d&rsquo;interf&eacute;rer dans la prise de
                        contact avec les autres Utilisateurs de l&rsquo;Application</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">&agrave; demeurer courtois avec les autres Utilisateurs</span>
                </li>
                <li class="c2 c3 li-bullet-0"><span class="c1">&agrave; ne pas porter atteinte aux Services ou &agrave;
                        l&rsquo;Application</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">ne pas publier sur l&rsquo;Application des contenus et photos
                        pornographiques ou portant atteinte &agrave; la pudeur et &agrave; la dignit&eacute; humaine</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">ne pas troubler l&rsquo;ordre public</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">ne pas commettre de d&eacute;lit de diffamation et plus largement
                        &agrave; ne pas tenir de propos injurieux en particulier &agrave; caract&egrave;re racial ou
                        discriminatoire</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">respecter les lois et r&egrave;glements en vigueur</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">ne pas prof&eacute;rer d&rsquo;incitation &agrave; la violence ou
                        faire l&rsquo;apologie du terrorisme, de crimes de guerre ou de crimes contre
                        l&rsquo;humanit&eacute;</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">ne pas utiliser l&rsquo;Application dans le but d&rsquo;organiser
                        des rassemblements et/ou des manifestations.</span></li>
            </ul>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur est ainsi pleinement responsable des informations et du Contenu
                    qu&rsquo;il communique &agrave; un autre Utilisateur et de l&rsquo;utilisation ult&eacute;rieure qui
                    pourrait en &ecirc;tre faite. DATELY recommande &agrave; l&rsquo;Utilisateur de ne pas communiquer
                    d&rsquo;informations et de contenus confidentiels ou sensibles tels que ses coordonn&eacute;es bancaires ou
                    les donn&eacute;es bancaires d&rsquo;autrui &agrave; un autre Utilisateur. Sans pr&eacute;judice des
                    stipulations qui pr&eacute;c&egrave;dent, DATELY ne saurait &ecirc;tre responsable de l&rsquo;utilisation
                    faite de ces informations par d&rsquo;autres Utilisateurs.</span></p>
            <p class="c2"><span class="c1">En cas de violation des dispositions susmentionn&eacute;es, l&rsquo;Utilisateur est
                    inform&eacute; que DATELY dispose de la facult&eacute; de cl&ocirc;turer sans d&eacute;lai et pr&eacute;avis
                    le Compte Personnel de l&rsquo;Utilisateur et de rendre indisponible son acc&egrave;s &agrave;
                    l&rsquo;Application et aux Services.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h4 class="c7" id="h.dk03vadzpuqg"><span class="c9">2. Informations et Contenus diffus&eacute;s</span></h4>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur est libre de diffuser le contenu de son choix sur
                    l&rsquo;Application sous r&eacute;serve que ce Contenu ne soit pas contraire aux dispositions l&eacute;gales
                    et r&eacute;glementaires en vigueur, ni ne porte atteinte aux droits d&rsquo;un tiers.</span></p>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur reconna&icirc;t ainsi &ecirc;tre responsable de tout Contenu
                    publi&eacute; sur l&rsquo;Application et s&rsquo;engage ainsi &agrave; ne pas diffuser de Contenu qui
                    contrevient aux lois et aux r&eacute;glementations en vigueur, ou qui porte atteinte aux droits des tiers,
                    ou qui soit vulgaire, discriminant ou injurieux.</span></p>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur garantit, notamment, que le Contenu publi&eacute; n&rsquo;est pas
                    rattach&eacute; &agrave; la r&eacute;pression de l&rsquo;apologie des crimes contre l&rsquo;humanit&eacute;,
                    l&rsquo;incitation &agrave; la haine raciale, &agrave; la haine &agrave; l&rsquo;&eacute;gard des personnes
                    en raison de leur sexe, de leur orientation, identit&eacute; sexuelle ou de leur handicap, ainsi
                    qu&rsquo;&agrave; la pornographie enfantine, l&rsquo;incitation &agrave; la violence, ni ne porte atteinte
                    &agrave; la dignit&eacute; humaine.</span></p>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur reconna&icirc;t par ailleurs que le Contenu publi&eacute; ne
                    porte pas atteinte aux droits des tiers et qu&rsquo;il ne s&rsquo;agit pas, plus particuli&egrave;rement,
                    d&rsquo;un contenu illicite et/ou contrefaisant et garantit d&eacute;tenir tous les droits de
                    propri&eacute;t&eacute; intellectuelle y aff&eacute;rents.</span></p>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur s&rsquo;engage &agrave; utiliser l&rsquo;Application uniquement
                    &agrave; des fins personnelles. Le r&eacute;seau ne doit &ecirc;tre exploit&eacute; ni &agrave; des fins
                    commerciales, ni promotionnelles, ni &eacute;lectorales, ni de recrutement. L&rsquo;envoi de Message
                    &agrave; but marketing et/ou commercial et/ou de newsletters via le Chat de l&rsquo;Application qui
                    inclurait, notamment et de mani&egrave;re non exhaustive, des contenus, indications ou liens hypertextes
                    renvoyant &agrave; des sites ou supports de soci&eacute;t&eacute;s tierces, et notamment concurrentes de
                    DATELY, est formellement prohib&eacute;. </span></p>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur reconna&icirc;t encore que tout Contenu notifi&eacute; par un
                    tiers aupr&egrave;s des &eacute;quipes de DATELY, selon les formes requises par la l&eacute;gislation en
                    vigueur, qui pr&eacute;sente un caract&egrave;re manifestement illicite, fera l&rsquo;objet d&rsquo;un
                    prompt retrait par les &eacute;quipes DATELY ayant pour effet de rendre ledit Contenu inaccessible sans
                    qu&rsquo;il soit n&eacute;cessaire d&rsquo;en informer pr&eacute;alablement l&rsquo;Utilisateur.</span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <h4 class="c7" id="h.m0z7yq32ohp3"><span class="c9">3. Rencontres r&eacute;elles</span></h4>
            <p class="c2"><span class="c1">DATELY met en garde les Utilisateurs qui d&eacute;sirent effectuer des rencontres
                    &laquo; r&eacute;elles &raquo;. Il appartient enti&egrave;rement aux Utilisateurs de v&eacute;rifier
                    l&rsquo;identit&eacute; des personnes avec qui ils organisent ces rencontres. Le d&eacute;roulement de ces
                    rencontres &laquo; r&eacute;elles &raquo; est organis&eacute; ind&eacute;pendamment de DATELY, sous la seule
                    responsabilit&eacute; des Utilisateurs. La responsabilit&eacute; de DATELY ne saurait &ecirc;tre
                    recherch&eacute;e pour les actes et probl&egrave;mes, quel qu&rsquo;en soit la nature, commis ou
                    provoqu&eacute;s par les Utilisateurs et/ou ancien Utilisateur lors des &eacute;v&egrave;nements se
                    d&eacute;roulant suite &agrave; l&rsquo;utilisation de l&rsquo;Application. &Agrave; cet &eacute;gard, le
                    Chat pr&eacute;sente volontairement l&rsquo;avantage, &agrave; nouveau dans le but de pr&eacute;server la
                    tranquillit&eacute; et la s&eacute;curit&eacute; de ses Utilisateurs, de permettre aux Utilisateurs de
                    d&eacute;cider des personnes qui seront autoris&eacute;es &agrave; voir leurs profils et &agrave; discuter
                    avec eux (via les fonctionnalit&eacute;s &laquo; Bloquer &raquo; et &laquo; Signaler &raquo;). </span></p>
            <p class="c2"><span class="c1">Il est fortement recommand&eacute; de ne pas d&eacute;voiler les informations qui
                    n&rsquo;apparaissent pas sur le profil de l&rsquo;Application telles que le num&eacute;ro de
                    t&eacute;l&eacute;phone, l&rsquo;adresse ou le nom de famille. DATELY conseille tr&egrave;s vivement, par
                    mesure de s&eacute;curit&eacute;, d&rsquo;organiser les rencontres r&eacute;elles uniquement dans des lieux
                    publics fortement fr&eacute;quent&eacute;s ou de pr&eacute;venir un proche et de ne d&eacute;voiler ses
                    coordonn&eacute;es qu&rsquo;apr&egrave;s un d&eacute;lai raisonnable.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <ol class="c18 lst-kix_s2k88b4dzu38-0 start" start="1">
                <li class="c2 c3 li-bullet-0"><span class="c1">Equipement</span></li>
            </ol>
            <p class="c2"><span class="c1">Les &eacute;quipements (mat&eacute;riels ou logiciels) n&eacute;cessaires &agrave;
                    l&rsquo;acc&egrave;s aux Services ainsi que tous les autres frais aff&eacute;rents, y compris les frais de
                    t&eacute;l&eacute;communication sont &agrave; la charge exclusive de l&rsquo;Utilisateur.
                    L&rsquo;Utilisateur est seul responsable du mat&eacute;riel utilis&eacute; dans le cadre du Service.</span>
            </p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <h3 class="c4" id="h.lf5nu8vx8477"><span class="c6">V. R&Ocirc;LE ET RESPONSABILIT&Eacute;S DE DATELY</span></h3>
            <h4 class="c7" id="h.k39wgen7f59f"><span class="c9">1. Interactions entre les Utilisateurs</span></h4>
            <p class="c2"><span class="c1">DATELY n&rsquo;est pas responsable de la conduite d&rsquo;un Utilisateur
                    vis-&agrave;-vis d&rsquo;un autre Utilisateur, ni des propos tenus ou du contenu diffus&eacute; par un
                    Utilisateur sur le Chat ou lors de la rencontre physique entre des Utilisateurs.</span></p>
            <p class="c2"><span class="c1">DATELY ne proc&egrave;de &agrave; aucune v&eacute;rification de la situation
                    d&rsquo;un Utilisateur, et plus particuli&egrave;rement de sa situation judiciaire, pr&eacute;alablement
                    &agrave; son utilisation des Services. Il appartient, par voie de cons&eacute;quence, &agrave; chaque
                    Utilisateur de se renseigner sur la situation de l&rsquo;Utilisateur et de le notifier, le cas
                    &eacute;ch&eacute;ant, &agrave; DATELY qui se r&eacute;serve le droit d&rsquo;alerter les services
                    administratifs ou judiciaires comp&eacute;tents.</span></p>
            <p class="c2"><span class="c1">DATELY d&eacute;cline par ailleurs toute responsabilit&eacute; sur toutes les
                    informations, en particulier les informations d&rsquo;ordre financier et plus g&eacute;n&eacute;ralement
                    toute information dite sensibles, susceptibles d&rsquo;&ecirc;tre transmises par un Utilisateur &agrave; un
                    autre Utilisateur en cas d&rsquo;utilisation frauduleuse ou illicite par la partie recevante.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h4 class="c7" id="h.gnh7hzas48v5"><span class="c9">2. Communication avec l&rsquo;Utilisateur</span></h4>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur pourra recevoir des notifications Push via l&rsquo;Application
                    afin d&rsquo;&ecirc;tre averti d&rsquo;&eacute;v&egrave;nements importants.</span></p>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur pourra r&eacute;gler ses pr&eacute;f&eacute;rences en
                    mati&egrave;re de notifications Push depuis son t&eacute;l&eacute;phone.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h4 class="c7" id="h.pw71a9h9mxy7"><span class="c9">3. Informations et Contenus diffus&eacute;s par les
                    Utilisateurs</span></h4>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur est seul responsable de ses actions et des donn&eacute;es
                    qu&rsquo;il publie via l&rsquo;Application.</span></p>
            <p class="c2"><span class="c1">DATELY conserve les donn&eacute;es de l&rsquo;Utilisateur pendant toute la
                    dur&eacute;e n&eacute;cessaire &agrave; l&rsquo;utilisation du Compte Personnel.</span></p>
            <p class="c2"><span class="c1">DATELY a la qualit&eacute; d&rsquo;h&eacute;bergeur des informations et Contenus
                    diffus&eacute;s &agrave; leur discr&eacute;tion par les Utilisateurs sur l&rsquo;Application et est, en
                    cons&eacute;quence, soumise au r&eacute;gime de la responsabilit&eacute; att&eacute;nu&eacute;e pr&eacute;vu
                    par les articles 6.I.2 et suivants de la loi n&deg;2004-575 du 2 juin 2004.</span></p>
            <p class="c2"><span class="c1">DATELY ne peut, &agrave; ce titre, &ecirc;tre tenue pour responsable des Informations
                    et Contenus diffus&eacute;s par l&rsquo;interm&eacute;diaire de l&rsquo;Application pour autant
                    qu&rsquo;elle n&rsquo;a pas exerc&eacute; un contr&ocirc;le sur les Informations et Contenus, et
                    qu&rsquo;elle n&rsquo;a pas eu une connaissance effective de leur caract&egrave;re manifestement
                    illicite.</span></p>
            <p class="c2"><span class="c1">DATELY n&rsquo;exerce aucune mod&eacute;ration des contenus publi&eacute;s par les
                    Utilisateurs et partag&eacute;s via l&rsquo;Application : en particulier, aucune photo, texte et contenu
                    vers lesquels redirigent les liens hypertextes publi&eacute;s par les Utilisateurs ne sont
                    v&eacute;rifi&eacute;s par les &eacute;quipes de DATELY. L&rsquo;Utilisateur pourra rapporter les contenus
                    illicites, portant atteinte au droit fran&ccedil;ais ou aux pr&eacute;sentes Conditions
                    G&eacute;n&eacute;rales d&rsquo;Utilisation au moyen des outils de mod&eacute;ration fournis par
                    l&rsquo;Application.</span></p>
            <p class="c2"><span class="c1">DATELY se r&eacute;serve le droit, dans l&rsquo;hypoth&egrave;se o&ugrave; un trop
                    grand nombre de contenus illicites proviendraient du m&ecirc;me Utilisateur, de supprimer sans d&eacute;lai
                    son Compte Personnel et de suspendre sa connexion &agrave; l&rsquo;Application.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h4 class="c7" id="h.23z9fxs9taz"><span class="c9">4. Disponibilit&eacute; et fonctionnement de l&rsquo;Application
                    et des Services</span></h4>
            <p class="c2"><span class="c1">DATELY fait ses meilleurs efforts aux fins de maintenir l&rsquo;Application
                    accessible aux Utilisateurs, sous r&eacute;serve (i) des cas de force majeure tels que reconnus par les
                    tribunaux fran&ccedil;ais ou (ii) du fait des Utilisateurs. DATELY pourra, n&eacute;anmoins, se trouver dans
                    l&rsquo;obligation de suspendre sans pr&eacute;avis et momentan&eacute;ment l&rsquo;acc&egrave;s &agrave;
                    l&rsquo;Application en raison de probl&egrave;mes techniques, mais &eacute;galement pour des raisons de
                    maintenance/r&eacute;paration/modification de l&rsquo;Application ou des Services. A ce titre, DATELY ne
                    garantit pas une accessibilit&eacute; ininterrompue &agrave; l&rsquo;Application et aux Services. </span>
            </p>
            <p class="c2"><span class="c1">DATELY fait, par ailleurs, ses meilleurs efforts aux fins d&rsquo;assurer la
                    pr&eacute;servation de la s&eacute;curit&eacute; de l&rsquo;Application. Toutefois, l&rsquo;Application peut
                    ponctuellement pr&eacute;senter des erreurs et/ou des bogues susceptibles d&rsquo;entra&icirc;ner des
                    dysfonctionnements des Services ou des pertes de donn&eacute;es sur l&rsquo;Application pour lesquels DATELY
                    ne peut en aucun cas &ecirc;tre responsable en raison des pr&eacute;cautions utiles mises en place par
                    DATELY et &eacute;tant pr&eacute;cis&eacute; que chaque Utilisateur doit sauvegarder une copie des
                    donn&eacute;es et ne peut pr&eacute;tendre &agrave; aucun d&eacute;dommagement &agrave; ce titre.</span></p>
            <p class="c2"><span class="c1">DATELY ne saurait &ecirc;tre tenu responsable de tout d&eacute;faut de fonctionnement
                    du terminal de l&rsquo;Utilisateur ou de probl&egrave;me de compatibilit&eacute; entre l&rsquo;Application
                    et le terminal. </span></p>
            <p class="c2"><span class="c1">DATELY n&rsquo;est pas responsable des pannes, interruptions ou du mauvais
                    fonctionnement des services du fournisseur d&rsquo;acc&egrave;s &agrave; internet de l&rsquo;Utilisateur,
                    ainsi que toute cause ext&eacute;rieure &agrave; DATELY qui pourrait interrompre ou endommager
                    l&rsquo;acc&egrave;s aux Services.</span></p>
            <p class="c2"><span class="c1">DATELY exclut &eacute;galement toute responsabilit&eacute; des dommages caus&eacute;s
                    par l&rsquo;Application au syst&egrave;me d&rsquo;exploitation de l&rsquo;Utilisateur, des interruptions ou
                    perturbations des Services ni des d&eacute;faillances mat&eacute;rielles ou logicielles de
                    l&rsquo;Application ou de tout dommage subi par la transmission d&rsquo;un virus informatique, d&rsquo;un
                    cheval de Troie ou de toute autre forme de programmation de nature &agrave; endommager ou d&eacute;truire
                    les fonctionnalit&eacute;s d&rsquo;un t&eacute;l&eacute;phone mobile ou d&rsquo;une tablette ou
                    d&rsquo;entraver son bon fonctionnement.</span></p>
            <p class="c2"><span class="c1">DATELY souhaite &eacute;galement pr&eacute;venir l&rsquo;Utilisateur contre tout
                    risque exceptionnel de r&eacute;ception de Messages ind&eacute;sirables ou de Messages &eacute;manant
                    pr&eacute;tendument de DATELY. L&rsquo;Utilisateur doit &ecirc;tre attentif et vigilant afin, si ces
                    hypoth&egrave;ses se r&eacute;alisent, d&rsquo;avertir DATELY.</span></p>
            <p class="c2"><span class="c1">DATELY ne pourra &ecirc;tre tenue pour responsable des dommages indirects,
                    infractions p&eacute;nales des Utilisateurs, perte commerciales, pertes de revenus, pertes de profits,
                    pertes de b&eacute;n&eacute;fices, pertes de contrats, pertes de client&egrave;le, perte de chance, manque
                    &agrave; gagner, pertes de donn&eacute;es, atteinte aux donn&eacute;es, atteinte &agrave; l&rsquo;image ou
                    &agrave; la r&eacute;putation ou pr&eacute;judice moral.</span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <p class="c2"><span class="c13">&nbsp;</span><span class="c13">5. Achats</span></p>
            <p class="c2"><span class="c11">Achats internes &agrave; l&#39;Application.</span><span class="c1">&nbsp;&Agrave;
                    tout moment, DATELY peut offrir des produits et services &agrave; l&#39;achat (&laquo; achats internes
                    &agrave; l&#39;Application &raquo;) au travers d&rsquo;iTunes ou Google Play (chacun &eacute;tant une
                    &laquo; Boutique de logiciels &raquo;). Si vous choisissez de r&eacute;aliser des achats internes &agrave;
                    l&#39;Application, vous serez pri&eacute; d&#39;indiquer les renseignements de votre compte sur la Boutique
                    de logiciels (&laquo; votre Compte IAP &raquo;), et les achats internes &agrave; l&#39;Application seront
                    factur&eacute;s &agrave; votre Compte IAP conform&eacute;ment aux conditions vous &eacute;tant
                    communiqu&eacute;es au moment des achats ainsi qu&#39;aux conditions g&eacute;n&eacute;rales relatives aux
                    achats internes &agrave; l&#39;Application qui s&#39;appliquent &agrave; votre Compte IAP. Certaines
                    Boutiques de logiciels peuvent vous facturer une taxe sur les ventes, en fonction de votre lieu de
                    r&eacute;sidence. Si vous achetez un abonnement p&eacute;riodique &agrave; renouvellement automatique au
                    travers d&#39;un achat interne &agrave; l&#39;Application, votre Compte IAP sera d&eacute;bit&eacute; pour
                    l&#39;abonnement jusqu&#39;&agrave; ce que vous l&#39;annuliez. Apr&egrave;s votre engagement pour une
                    p&eacute;riode initiale d&#39;abonnement, et ensuite apr&egrave;s toute p&eacute;riode d&#39;abonnement
                    ult&eacute;rieure, votre abonnement se renouvellera automatiquement pour une p&eacute;riode
                    &eacute;quivalente suppl&eacute;mentaire, au prix que vous avez approuv&eacute; lors de la souscription. Si
                    vous ne souhaitez pas que votre abonnement se renouvelle automatiquement, ou si vous voulez modifier ou
                    renouveler votre abonnement, vous devrez vous connecter &agrave; votre compte IAP et suivre les instructions
                    pour annuler votre abonnement, m&ecirc;me si vous avez supprim&eacute; votre compte aupr&egrave;s de nous
                    d&#39;une autre fa&ccedil;on ou si vous avez supprim&eacute; l&#39;Application de votre appareil. Il ne
                    suffit pas de supprimer votre compte sur TURN UP ou de supprimer l&#39;Application de votre appareil pour
                    annuler votre abonnement. DATELY conservera tous les fonds d&eacute;bit&eacute;s de votre compte IAP
                    jusqu&#39;&agrave; ce que vous annuliez votre abonnement via votre compte IAP.</span></p>
            <h3 class="c4" id="h.cyhpnrerqr7"><span class="c6">VI. PROPRI&Eacute;T&Eacute; INTELLECTUELLE</span></h3>
            <h4 class="c7" id="h.pkygds12tp8u"><span class="c9">1. Propri&eacute;t&eacute; de l&rsquo;Application</span></h4>
            <p class="c2"><span class="c1">Les d&eacute;nominations des Services, les marques, les logos, les graphismes, les
                    photographies, les animations, les vid&eacute;os et les textes contenus sur l&rsquo;Application et dans les
                    Services sont la propri&eacute;t&eacute; de DATELY, et ne peuvent &ecirc;tre reproduits, utilis&eacute;s ou
                    repr&eacute;sent&eacute;s sans l&#39;autorisation expresse de la DATELY, sous peine de poursuites
                    judiciaires.</span></p>
            <p class="c2"><span class="c1">Les droits d&#39;utilisation conc&eacute;d&eacute;s &agrave; l&rsquo;Utilisateur sont
                    r&eacute;serv&eacute;s &agrave; un usage priv&eacute; et personnel dans le cadre et pour la dur&eacute;e de
                    l&#39;inscription aux Services. Toute autre utilisation par l&rsquo;Utilisateur est interdite.</span></p>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur s&#39;interdit notamment de modifier, copier, reproduire,
                    t&eacute;l&eacute;charger, diffuser, transmettre, exploiter commercialement et/ou distribuer de quelque
                    fa&ccedil;on que ce soit les Services ou les codes informatiques des &eacute;l&eacute;ments composant les
                    Services et l&rsquo;Application. Sans l&rsquo;autorisation expresse et pr&eacute;alable de DATELY, cela
                    constituerait une contrefa&ccedil;on sanctionn&eacute;e par les dispositions du Code de la
                    Propri&eacute;t&eacute; Intellectuelle.</span></p>
            <p class="c2"><span class="c1">L&rsquo;ensemble de ces &eacute;l&eacute;ments est mis &agrave; la disposition de
                    l&rsquo;Utilisateur qui s&rsquo;engage &agrave; les respecter, &agrave; ne pas les modifier, ni les
                    extraire, ou en faire un usage prohib&eacute; par la loi et les r&eacute;glementations en vigueur.</span>
            </p>
            <p class="c2"><span class="c1">DATELY conc&egrave;de &agrave; l&rsquo;Utilisateur, une licence limit&eacute;e, non
                    transmissible et non exclusive sur l&rsquo;Application aux fins de lui permettre de
                    t&eacute;l&eacute;charger et d&rsquo;installer une copie de l&rsquo;Application sur ses appareils mobiles
                    et/ou tablettes &agrave; des fins d&rsquo;utilisations strictement personnelles.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h4 class="c7" id="h.jwjx3snd3vke"><span class="c9">2. Propri&eacute;t&eacute; et licence des Contenus des
                    Utilisateurs</span></h4>
            <p class="c2"><span class="c1">Tout &eacute;l&eacute;ment, contenu et information, en ce compris les marques,
                    d&eacute;nominations, logos, textes, visuels, images, photographies, musiques, vid&eacute;os, dessins,
                    mod&egrave;les, logiciels, bases de donn&eacute;es, etc., fournis par l&rsquo;Utilisateur, pour mise
                    &agrave; disposition et diffusion par l&rsquo;interm&eacute;diaire des Services de DATELY et notamment de
                    son Application, restent la propri&eacute;t&eacute; dudit Utilisateur.</span></p>
            <p class="c2"><span class="c1">Sans pr&eacute;judice de ce qui pr&eacute;c&egrave;de, l&rsquo;Utilisateur
                    conc&egrave;de &agrave; DATELY de mani&egrave;re non exclusive, gratuite et mondiale le droit de reproduire
                    et de diffuser le Contenu publi&eacute; en tout ou partie par l&rsquo;Utilisateur sur
                    l&rsquo;Application.</span></p>
            <p class="c2"><span class="c1">Le droit de reproduction est ainsi conc&eacute;d&eacute; &agrave; DATELY aux fins :
                    (i) d&rsquo;ex&eacute;cuter les Services et notamment de permettre &agrave; l&rsquo;Utilisateur de
                    visualiser les Contenus publi&eacute;s par d&rsquo;autres Utilisateurs sur l&rsquo;Application et (ii)
                    &agrave; des fins de communication, promotion, et/ou publicit&eacute; des Services et/ou de
                    l&rsquo;Application et/ou de DATELY, en tout format, sur tous supports, et par tout proc&eacute;d&eacute;,
                    connus ou inconnus &agrave; ce jour, et notamment mais non-exclusivement, sur tous m&eacute;dias et tous
                    r&eacute;seaux, sur des sites partenaires de tiers.</span></p>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur reconna&icirc;t et accepte express&eacute;ment que lors de la
                    diffusion du Contenu sur les r&eacute;seaux sociaux par DATELY, seules les conditions
                    g&eacute;n&eacute;rales d&rsquo;utilisation du r&eacute;seau social seront exclusivement applicables.</span>
            </p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h3 class="c4" id="h.68bf06ljx9v6"><span class="c6">VII. LIENS VERS D&rsquo;AUTRES APPLICATIONS</span></h3>
            <p class="c2"><span class="c1">L&rsquo;Application peut contenir des liens vers des applications externes
                    susceptibles d&rsquo;int&eacute;resser l&rsquo;Utilisateur.</span></p>
            <p class="c2"><span class="c1">La responsabilit&eacute; de DATELY ne saurait &ecirc;tre recherch&eacute;e ni
                    engag&eacute;e quant au contenu de l&rsquo;une de ces Applications tierces, auquel l&rsquo;Utilisateur
                    aurait eu acc&egrave;s au moyen de ces liens.</span></p>
            <p class="c2"><span class="c1">En outre, DATELY ne dispose d&#39;aucun moyen de contr&ocirc;le du contenu de ces
                    applications tierces qui demeurent totalement ind&eacute;pendantes de DATELY. De surcro&icirc;t,
                    l&#39;existence d&#39;un lien entre DATELY et une Application tierce ne signifie en aucun cas que DATELY
                    approuve &agrave; quelque titre que ce soit le contenu de cette Application et a fortiori l&#39;usage qui
                    pourrait en &ecirc;tre fait.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h3 class="c4" id="h.xfuhg2i799ve"><span class="c6">VIII. R&Eacute;SILIATION / SUSPENSION</span></h3>
            <p class="c2"><span class="c1">DATELY se r&eacute;serve le droit de r&eacute;silier les Conditions
                    G&eacute;n&eacute;rales d&rsquo;Utilisation sans pr&eacute;avis et sans motif particulier pour des raisons
                    de convenance. De m&ecirc;me, l&rsquo;Utilisateur pourra r&eacute;silier les Conditions
                    G&eacute;n&eacute;rales d&rsquo;Utilisation sans pr&eacute;avis et sans motif particulier pour des raisons
                    de convenance en cliquant sur le bouton de suppression du Compte Personnel se trouvant dans la section
                    &laquo; Profil &raquo; de l&rsquo;Application.</span></p>
            <p class="c2"><span class="c1">DATELY se r&eacute;serve le droit de r&eacute;silier les Conditions
                    G&eacute;n&eacute;rales d&rsquo;Utilisation sans pr&eacute;avis et sans motif particulier et/ou de suspendre
                    un Utilisateur de l&rsquo;acc&egrave;s &agrave; ses Services et notamment &agrave; son Application et/ou de
                    supprimer et/ou de suspendre un Contenu mis &agrave; disposition par l&rsquo;interm&eacute;diaire de ses
                    Services et notamment de son Application par un Utilisateur, &agrave; tout moment, sans pr&eacute;avis et
                    sans avis de l&rsquo;Utilisateur en cas de :</span></p>
            <ul class="c18 lst-kix_66d4hqf36z9p-0 start">
                <li class="c2 c3 li-bullet-0"><span class="c1">violation substantielle des Conditions G&eacute;n&eacute;rales
                        d&rsquo;Utilisation</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">publications de Contenus &agrave; caract&egrave;re diffamatoire,
                        d&eacute;nigrant, injurieux, obsc&egrave;ne, violent, raciste</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">en cas d&rsquo;exercice d&rsquo;activit&eacute;s
                        dissimul&eacute;es, frauduleuses ou ill&eacute;gales par l&rsquo;interm&eacute;diaire de ses Services et
                        notamment de son Application</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c1">plus g&eacute;n&eacute;ralement, en cas de violation des lois et
                        r&egrave;glements applicables ou des droits des tiers.</span></li>
            </ul>
            <p class="c2"><span class="c1">A compter de la cl&ocirc;ture de son compte et/ou de la suppression de
                    l&rsquo;acc&egrave;s &agrave; l&rsquo;Application, l&rsquo;Utilisateur reconna&icirc;t que l&rsquo;ensemble
                    de ses Contenus publi&eacute;s ne seront plus accessibles par l&rsquo;interm&eacute;diaire de
                    l&rsquo;Application.</span></p>
            <p class="c2"><span class="c1">En cas de r&eacute;siliation des Conditions G&eacute;n&eacute;rales
                    d&rsquo;Utilisation, les stipulations de celles-ci qui devraient raisonnablement perdurer apr&egrave;s la
                    r&eacute;siliation de celles-ci, resteront en vigueur.</span></p>
            <p class="c2 c12"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h3 class="c4" id="h.gc8y3tb8e19m"><span class="c6">IX. DUR&Eacute;E DES CONDITIONS G&Eacute;N&Eacute;RALES
                    D&rsquo;UTILISATION</span></h3>
            <p class="c2"><span class="c1">L&rsquo;Utilisateur est soumis au respect des Conditions G&eacute;n&eacute;rales
                    d&rsquo;Utilisation &agrave; compter de l&rsquo;ouverture et jusqu&rsquo;&agrave; la cl&ocirc;ture de son
                    Compte Personnel.</span></p>
            <p class="c2"><span class="c1">Les Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation applicables sont celles en
                    vigueur au jour de l&rsquo;utilisation de l&rsquo;Application par l&rsquo;Utilisateur.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h3 class="c4" id="h.vqdvvmxy2g2h"><span class="c6">X. MODIFICATION</span></h3>
            <p class="c2"><span class="c1">DATELY se r&eacute;serve le droit, &agrave; tout moment et de mani&egrave;re
                    unilat&eacute;rale, de compl&eacute;ter ou de modifier les pr&eacute;sentes Conditions
                    G&eacute;n&eacute;rales d&rsquo;Utilisation de la fa&ccedil;on qu&rsquo;elle juge n&eacute;cessaire, ou de
                    mettre fin aux Services. </span></p>
            <p class="c2"><span class="c1">Ces modifications seront signal&eacute;es par tout moyen utile et notamment par la
                    publication d&rsquo;une mention de mise &agrave; jour avec la date de mise &agrave; jour, au sein des
                    Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation.</span></p>
            <p class="c2"><span class="c1">Un Utilisateur qui n&rsquo;accepte pas les modifications des Conditions
                    G&eacute;n&eacute;rales d&rsquo;Utilisation, ne pourra pas continuer et devra cesser toute utilisation des
                    Services et notamment de l&rsquo;Application, sans pr&eacute;judice du respect des obligations ayant
                    vocation &agrave; perdurer &agrave; la cessation de cette utilisation.</span></p>
            <p class="c2"><span class="c1">Il incombe &agrave; l&rsquo;Utilisateur de lire attentivement les Conditions
                    G&eacute;n&eacute;rales d&rsquo;Utilisation lors de leur mise &agrave; jour et de prendre pleinement
                    connaissance des modifications engendr&eacute;es par cette derni&egrave;re. Le fait que l&rsquo;Utilisateur
                    continue &agrave; utiliser les Services de l&rsquo;Application apr&egrave;s la mise &agrave; jour des
                    Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation vaut acceptation sans r&eacute;serve de sa part aux
                    modifications apport&eacute;es aux Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation.</span></p>
            <p class="c2"><span class="c1">En cas de d&eacute;saccord avec les modifications des Conditions
                    G&eacute;n&eacute;rales d&rsquo;Utilisation, l&rsquo;Utilisateur a la facult&eacute; de mettre fin aux
                    Services et de r&eacute;silier son Compte conform&eacute;ment &agrave; l&rsquo;article VIII.</span></p>
            <p class="c2"><span class="c1">Les nouvelles Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation
                    s&rsquo;appliqueront imm&eacute;diatement pour les Utilisateurs.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h3 class="c4" id="h.r7cp7gfxahh5"><span class="c6">XI. AUTONOMIE</span></h3>
            <p class="c2"><span class="c1">Dans l&rsquo;hypoth&egrave;se o&ugrave; l&rsquo;une des stipulations des
                    pr&eacute;sentes Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation serait d&eacute;clar&eacute;e nulle
                    ou inapplicable par l&rsquo;effet d&rsquo;une loi d&rsquo;un r&egrave;glement ou d&rsquo;une d&eacute;cision
                    juridictionnelle &eacute;manant d&rsquo;un tribunal comp&eacute;tent, les autres stipulations conserveront
                    leur validit&eacute; et leur force, sauf &agrave; imaginer que les pr&eacute;sentes Conditions
                    G&eacute;n&eacute;rales d&rsquo;Utilisation soient d&eacute;natur&eacute;es ou que cela entra&icirc;ne un
                    d&eacute;s&eacute;quilibre significatif des obligations &agrave; la charge des parties. </span></p>
            <p class="c2"><span class="c1">Dans cette hypoth&egrave;se, DATELY substituera d&egrave;s que possible une
                    stipulation valide et satisfaisante en lieu et &agrave; la place de la stipulation dont la validit&eacute;
                    est affect&eacute;e.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h3 class="c4" id="h.57wtatdlkzk0"><span class="c6">XII. LOI APPLICABLE</span></h3>
            <p class="c2"><span class="c1">Les pr&eacute;sentes Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation sont
                    soumises &agrave; la loi fran&ccedil;aise.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h3 class="c4" id="h.t1gbk3s8d79f"><span class="c6">XIII. JURIDICTIONS COMPETENTES</span></h3>
            <p class="c2"><span class="c1">En cas de litige, l&rsquo;Utilisateur s&#39;adressera dans un premier temps aux
                    &eacute;quipes de DATELY afin de trouver une solution amiable. DATELY examinera les demandes raisonnables de
                    r&eacute;solution de litige au moyen de proc&eacute;dures alternatives comme par exemple une
                    m&eacute;diation ou un arbitrage ou encore par la voie du droit collaboratif.</span></p>
            <p class="c2"><span class="c1">A d&eacute;faut de r&eacute;solution du litige au moyens de proc&eacute;dures
                    alternatives, toute contestation et/ou difficult&eacute; d&rsquo;interpr&eacute;tation ou
                    d&rsquo;ex&eacute;cution des conditions g&eacute;n&eacute;rales d&rsquo;utilisation rel&egrave;vera de la
                    comp&eacute;tence des tribunaux fran&ccedil;ais.</span></p>
            <p class="c2"><span class="c1">Ni DATELY ni l&rsquo;Utilisateur ne sont autoris&eacute;s &agrave; divulguer des
                    informations sur le contrat les liant en cas de litige et chacune des parties s&rsquo;engage &agrave;
                    respecter une clause de confidentialit&eacute; qui l&rsquo;oblige &agrave; pr&eacute;f&eacute;rer une
                    n&eacute;gociation confidentielle en cas de diff&eacute;rend.</span></p>
        </body>
        
        </html>
    `
    };

    return <div dangerouslySetInnerHTML={htmlContent} />;
};

export default TermsOfUse;

import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../assets/TurnUpLogoNew.webp';
import downloadPhone from '../assets/downloadPhone.webp';
import konbini from '../assets/konbini.png';
import COMFR1 from '../assets/COMFR1.png';
import COMFR2 from '../assets/COMFR2.png';
import COMFR3 from '../assets/COMFR3.png';
import COMFR4 from '../assets/COMFR4.png';
import COMFR5 from '../assets/COMFR5.png';
import COMFR6 from '../assets/COMFR6.png';
import COMFR7 from '../assets/COMFR7.png';
import COMFR8 from '../assets/COMFR8.png';
import COMFR9 from '../assets/COMFR9.png';
import COMFR10 from '../assets/COMFR10.png';
import COMEN1 from '../assets/COMEN1.png';
import COMEN2 from '../assets/COMEN2.png';
import COMEN3 from '../assets/COMEN3.png';
import COMEN4 from '../assets/COMEN4.png';
import COMEN5 from '../assets/COMEN5.png';
import COMEN6 from '../assets/COMEN6.png';
import COMEN7 from '../assets/COMEN7.png';
import COMEN8 from '../assets/COMEN8.png';
import COMEN9 from '../assets/COMEN9.png';

import backgroundPhone from '../assets/backgroundPhone.webp';
import backgroundDesk from '../assets/backgroundDesk.webp';
import femmeactuelle from '../assets/femmeactuelle.png';
import lebonbon from '../assets/lebonbon.png';
import madmoizelle from '../assets/madmoizelle.png';
import nrj from '../assets/nrj.png';
import trax from '../assets/trax.jpg';
import couple from '../assets/coupleImage.webp';
import appleLogo from '../assets/ApplLogo.png';
import androidLogo from '../assets/androidLogo.png';
import { library } from '@fortawesome/fontawesome-svg-core'
import { useMediaQuery } from 'react-responsive'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import LocalizedStrings from 'react-localization';

library.add(fab, faStar)

const useDesktopMediaQuery = () =>
  useMediaQuery({ query: "(min-width: 1280px)" })

const useTabletAndBelowMediaQuery = () =>
  useMediaQuery({ query: "(max-width: 1279px)" })

const Desktop = ({ children }) => {
  const isDesktop = useDesktopMediaQuery()

  return isDesktop ? children : null
}

const TabletAndBelow = ({ children }) => {
  const isTabletAndBelow = useTabletAndBelowMediaQuery()

  return isTabletAndBelow ? children : null
}

const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return "https://play.google.com/store/apps/details?id=com.dately&hl=fr&gl=US";
  }

  if (/android/i.test(userAgent)) {
    return "https://play.google.com/store/apps/details?id=com.dately&hl=fr&gl=US";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "https://apps.apple.com/app/turn-up/id1353082383";
  }

  return "https://play.google.com/store/apps/details?id=com.dately&hl=fr&gl=US"
}


const Home = () => {
  const history = useHistory();

  const goToPrivacyPolicy = () => {
    history.push('/privacy');
  };

  const goToCGU = () => {
    history.push('/terms');
  };

  const fr = strings.language === 'fr'
  const android = getMobileOperatingSystem() === "https://play.google.com/store/apps/details?id=com.dately&hl=fr&gl=US"

  return (
    <div style={{ flex: 1, backgroundColor: '#10101B', overflow: "hidden" }}>
      <Desktop>
        <div style={{ display: "flex", flex: 1, position: 'fixed', top: 0, right: 0, left: 0, backgroundColor: '#222233', height: 50, justifyContent: "space-between", alignItems: "center", paddingLeft: 10, paddingRight: 10, }}>
          <img src={logo} style={{ height: 45 }} alt="Turn Up Logo" />
          <a href={`${getMobileOperatingSystem()}`} style={{ textDecoration: "none", display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={downloadPhone} style={{ height: 40, marginRight: 5 }} alt="download" />
            </div>
          </a>
        </div>

        <div style={{ flex: 1, backgroundImage: `url(${backgroundDesk})`, backgroundSize: '100%', display: "flex", paddingBottom: 40, borderBottomRightRadius: 30 }}>
          <div style={{ flex: 1, display: "flex" }}>
            <div style={{ flex: 40, display: "flex", alignItems: 'center' }}>
              <img src={couple} style={{ height: "calc(85vh)", width: "calc(80vh)", marginTop: 80 }} alt="Couple" />
            </div>

            <div style={{ display: 'flex', flex: 60, justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', paddingLeft: 200 }} >
              <h1 style={{ fontFamily: "Inter", color: "#E7E7FF", fontWeight: 500, fontSize: 60, width: "calc(50vh)", letterSpacing: -3, lineHeight: 1 }}>{strings.title}</h1>
              <h2 style={{ fontFamily: "Inter", color: "#E7E7FF", fontWeight: 400, width: "calc(50vh)", fontSize: 25, marginTop: 0, marginBottom: 50, letterSpacing: -1, lineHeight: 1.3 }}>{strings.subTitle}</h2>

              <div style={{ backgroundColor: '#222233', padding: 10, borderRadius: 20 }}>
                <a
                  href="https://apps.apple.com/app/turn-up/id1353082383"

                  style={{ textDecoration: 'none', background: "#BBF442", width: "calc(35vh)", height: 50, borderRadius: 12, alignContent: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>

                  <img src={appleLogo} style={{ height: "calc(4vh)", width: "calc(4vh)", paddingBottom: 3, paddingRight: 8, marginLeft: -5 }} alt="appleLogo" />

                  <div style={{ flexDirection: 'column' }}>

                    <h1 style={{ textDecoration: "none", color: "#10101B", fontFamily: "Inter", fontWeight: 600, fontSize: 20, letterSpacing: -1 }}>{strings.appStore}</h1>
                  </div>
                </a>

                <a
                  href="https://play.google.com/store/apps/details?id=com.dately"

                  style={{ textDecoration: 'none', background: "#BBF442", width: "calc(35vh)", height: 50, borderRadius: 12, alignContent: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: 15 }}>

                  <img src={androidLogo} style={{ height: "calc(3.5vh)", width: "calc(3.5vh)", paddingRight: 10 }} alt="androidLogo" />

                  <div style={{ flexDirection: 'column' }}>

                    <h1 href={`${getMobileOperatingSystem()}`} style={{ textDecoration: "none", color: "#10101B", fontFamily: "Inter", fontWeight: 600, fontSize: 20, letterSpacing: -1 }}>{strings.googlePlay}</h1>
                  </div>
                </a>
              </div>

            </div>
          </div>

        </div>

        <div style={{ backgroundImage: `url(${backgroundDesk})`, backgroundSize: '100%' }}>

          {fr ?
            <div>
              <h1 style={{ fontFamily: "Inter", color: "#F1F1FF", fontWeight: 500, fontSize: 50, letterSpacing: -3, lineHeight: 1.2, textAlign: 'center', marginBottom: 30, paddingTop: 40, marginTop: 0 }}>{strings.yourReviews}</h1>
              <div style={{ display: "flex", flex: 1, alignItems: "center", flexDirection: 'column', overflow: 'auto' }}>
                <div style={{ display: "flex", flexDirection: "row", overflow: 'auto', whiteSpace: 'nowrap', alignSelf: 'flex-start' }}>

                  <img style={{ height: 300, width: "auto", marginLeft: 20, marginTop: 5, marginBottom: 5, marginRight: 5 }} src={COMFR1} alt="REVIEW1" />
                  <img style={{ height: 300, width: "auto", margin: 5 }} src={COMFR2} alt="REVIEW2" />
                  <img style={{ height: 300, width: "auto", margin: 5 }} src={COMFR3} alt="REVIEW3" />
                  <img style={{ height: 300, width: "auto", margin: 5 }} src={COMFR4} alt="REVIEW4" />
                  <img style={{ height: 300, width: "auto", margin: 5 }} src={COMFR5} alt="REVIEW5" />
                  <img style={{ height: 300, width: "auto", margin: 5 }} src={COMFR6} alt="REVIEW6" />
                  <img style={{ height: 300, width: "auto", margin: 5 }} src={COMFR7} alt="REVIEW7" />
                  <img style={{ height: 300, width: "auto", margin: 5 }} src={COMFR8} alt="REVIEW8" />
                  <img style={{ height: 300, width: "auto", margin: 5 }} src={COMFR9} alt="REVIEW9" />
                  <img style={{ height: 300, width: "auto", marginLeft: 5, marginTop: 5, marginBottom: 5, marginRight: 20 }} src={COMFR10} alt="REVIEW10" />

                </div>

              </div>
            </div>

            :

            <div>
              <h1 style={{ fontFamily: "Inter", color: "#F1F1FF", fontWeight: 500, fontSize: 50, letterSpacing: -3, lineHeight: 1.2, textAlign: 'center', marginBottom: 20, marginTop: 40 }}>{strings.yourReviews}</h1>
              <div style={{ display: "flex", flex: 1, alignItems: "center", flexDirection: 'column', overflow: 'auto' }}>
                <div style={{ display: "flex", flexDirection: "row", overflow: 'auto', whiteSpace: 'nowrap', alignSelf: 'flex-start' }}>

                  <img style={{ height: 220, width: "auto", marginLeft: 20, marginTop: 5, marginBottom: 5, marginRight: 5 }} src={COMEN1} alt="REVIEW1" />
                  <img style={{ height: 220, width: "auto", margin: 5 }} src={COMEN2} alt="REVIEW2" />
                  <img style={{ height: 220, width: "auto", margin: 5 }} src={COMEN3} alt="REVIEW3" />
                  <img style={{ height: 220, width: "auto", margin: 5 }} src={COMEN4} alt="REVIEW4" />
                  <img style={{ height: 220, width: "auto", margin: 5 }} src={COMEN5} alt="REVIEW5" />
                  <img style={{ height: 220, width: "auto", margin: 5 }} src={COMEN6} alt="REVIEW6" />
                  <img style={{ height: 220, width: "auto", margin: 5 }} src={COMEN7} alt="REVIEW7" />
                  <img style={{ height: 220, width: "auto", margin: 5 }} src={COMEN8} alt="REVIEW8" />
                  <img style={{ height: 220, width: "auto", marginRight: 20, marginTop: 5, marginBottom: 5, marginLeft: 5 }} src={COMEN9} alt="REVIEW9" />



                </div>

              </div>
            </div>
          }

        </div>

        {fr ? <div style={{ display: "flex", flex: 1, height: "calc(120vh)", backgroundImage: `url(${backgroundDesk})`, backgroundSize: '100%', justifyContent: "center", alignItems: "center", flexDirection: "column", paddingBottom: 200, paddingTop: 100 }}>
          <div >
            <h1 style={{ fontFamily: "Inter", color: "#F1F1FF", fontWeight: 500, fontSize: 50, width: "calc(200vh)", letterSpacing: -3, lineHeight: 1.2, textAlign: 'center', marginBottom: 10 }}>{strings.press}</h1>
          </div>

          <div style={{ flexDirection: 'row', display: 'inline-flex', padding: 20 }}>
            <a style={{ textDecoration: "none", color: "#10101B", padding: 12, flex: 33 }} href="https://www.konbini.com/fr/musique/application-de-rencontres-va-vous-aider-a-trouver-votre-moitie-en-festival/">
              <div style={{ backgroundColor: "#F1F1FF", display: "flex", borderRadius: 15, alignContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 20 }}>
                <img style={{ height: 120, width: "auto" }} src={konbini} alt="konbini" />
                <p style={{ fontFamily: "Inter", fontSize: 19, letterSpacing: -1, width: "calc(40vh)" }}>
                  "Cette invention offerte par les développeurs est parfaite pour ceux qui ont toujours rêvé d’un date en festival ou pour ceux qui en on marre de louper des événements car leurs amis ne partagent pas la même passion qu’eux. Cette application devrait donc
                  vous donner le sourire."</p></div>
            </a>

            <a style={{ textDecoration: "none", color: "#10101B", padding: 12, flex: 33 }} href="https://nuit.lebonbon.fr/news-nuit/adopte-une-technophile-un-tranceux-ou-un-edm-addict-avec-ce-tinder-2-0/">
              <div style={{ backgroundColor: "#F1F1FF", display: "flex", borderRadius: 15, alignContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 20 }}>
                <img style={{ height: 120, width: "auto" }} src={lebonbon} alt="lebonbon" />
                <p style={{ fontFamily: "Inter", fontSize: 19, letterSpacing: -1, width: "calc(40vh)" }}>
                  “Le petit plus, c’est que tu trouveras dans tes propositions uniquement des personnes qui ont les mêmes centres d’intérêts musicaux que toi. [...] Et Turn Up ne s’arrête pas là, puisqu’elle te permet aussi de découvrir ou organiser des évènements près
                  de chez toi, avec d’autres utilisateurs de l’application. [...] Magical !”</p></div>
            </a>

            <a style={{ textDecoration: "none", color: "#10101B", padding: 12, flex: 33 }} href="https://www.madmoizelle.com/turn-up-application-rencontres-1042436">
              <div style={{ backgroundColor: "#F1F1FF", display: "flex", borderRadius: 15, alignContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 20 }}>
                <img style={{ height: 120, width: "auto" }} src={madmoizelle} alt="madmoizelle" />
                <p style={{ fontFamily: "Inter", fontSize: 19, letterSpacing: -1, width: "calc(40vh)" }}>
                  "Souvent, avec une passion pour un genre musical, viennent de vrais codes sociaux, un réel univers, et je trouve ça tellement agréable et précieux de pouvoir partager ces moments avec sa moitié ou son plan cul !"</p></div>
            </a>
          </div>
          <div style={{ flexDirection: 'row', display: 'inline-flex' }}>
            <a style={{ textDecoration: "none", color: "#10101B", padding: 12, flex: 33, marginTop: -43 }} href="https://www.femmeactuelle.fr/amour/news-amour/tinder-musical-cette-appli-vous-permet-de-vous-rencontrer-a-des-concerts-2091039">
              <div style={{ backgroundColor: "#F1F1FF", display: "flex", borderRadius: 15, alignContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 20 }}>
                <img style={{ height: 120, width: "auto" }} src={femmeactuelle} alt="femmeactuelle" />
                <p style={{ fontFamily: "Inter", fontSize: 19, letterSpacing: -1, width: "calc(40vh)" }}>
                  "Célibataire, en quête du grand amour ou de nouvelles rencontres ? Cette application pourrait bien vous donner un coup de pouce lors d’un concert ou d’un festival, en vous permettant de rentrer en contact avec des personnes qui partagent la même passion
                  que vous pour la musique !"</p></div>
            </a>
            <a style={{ textDecoration: "none", color: "#10101B", padding: 12, flex: 33, marginTop: -20 }} href="https://www.traxmag.com/turn-up-le-tinder-pour-les-amoureux-de-musique-vient-de-sortir/">
              <div style={{ backgroundColor: "#F1F1FF", display: "flex", borderRadius: 15, alignContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 20 }}>
                <img style={{ height: 120, width: "auto" }} src={trax} alt="trax" />
                <p style={{ fontFamily: "Inter", fontSize: 19, letterSpacing: -1, width: "calc(40vh)" }}>
                  “Turn Up, voici le nom de cette nouvelle application de rencontre en milieu festif. Axée sur la musique, il est possible de sélectionner son style de prédilection et ainsi programmer son rendez-vous en club, rave ou festival.”</p></div>
            </a>
            <a style={{ textDecoration: "none", color: "#10101B", padding: 12, flex: 33, marginTop: -88 }} href="https://www.nrj.be/article/2522/turn-up-le-tinder-des-salles-de-concert">
              <div style={{ backgroundColor: "#F1F1FF", display: "flex", borderRadius: 15, alignContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 20 }}>
                <img style={{ height: 120, width: "auto" }} src={nrj} alt="nrj" />
                <p style={{ fontFamily: "Inter", fontSize: 19, letterSpacing: -1, width: "calc(40vh)" }}>
                  "Pour vous, la musique est essentielle ? Vous ne tomberiez jamais amoureux de quelqu’un qui a des goûts musicaux opposés au vôtre ? Cette app est faite pour vous !"</p></div>
            </a>
          </div>
        </div> : <div></div>}

        <div style={{ position: 'aboslute', bottom: 0, height: 60, backgroundColor: '#10101B', flex: 1 }}>
          <div style={{ justifyContent: 'flex-end', display: "flex" }}>
            <button style={{ color: "#F1F1FF", fontFamily: "Inter", background: "none", border: "none", cursor: "pointer", marginRight: "20px", marginTop: "20px", textDecoration: "underline" }} onClick={goToPrivacyPolicy}>
              {strings.mentions}
            </button>
            <button style={{ color: "#F1F1FF", fontFamily: "Inter", background: "none", border: "none", cursor: "pointer", marginRight: "20px", marginTop: "20px", textDecoration: "underline" }} onClick={goToCGU}>
              {strings.cgu}
            </button>
            <button style={{ color: "#F1F1FF", fontFamily: "Inter", background: "none", border: "none", cursor: "pointer", marginRight: "20px", marginTop: "20px", textDecoration: "underline" }} onClick={goToPrivacyPolicy}>
              {strings.charte}
            </button>
          </div>
        </div>
      </Desktop>



      <TabletAndBelow>
        <div style={{ overflow: "hidden" }}>

          <div style={{ display: "flex", flex: 1, position: 'fixed', top: 0, right: 0, left: 0, backgroundColor: '#222233', height: 60, justifyContent: "space-between", alignItems: "center", paddingLeft: 10, paddingRight: 10 }}>
            <img src={logo} style={{ height: 50 }} alt="Turn Up Logo" />
            <a href={`${getMobileOperatingSystem()}`} style={{ textDecoration: "none", }}>
              <img src={downloadPhone} style={{ height: 43, marginRight: 3 }} alt="downloadPhone" />
            </a>
          </div>

          <div style={{ flex: 1, backgroundImage: `url(${backgroundPhone})`, backgroundSize: '100%', display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 'calc(10vh)', paddingBottom: 'calc(8vh)' }}>
            <h1 style={{ fontFamily: 'Inter', color: "#E7E7FF", fontWeight: 500, fontSize: "calc(7.5vh)", textAlign: 'center', letterSpacing: -3.5, lineHeight: 1.2, marginBottom: "calc(6vh)" }}>{strings.title}</h1>

            <div style={{ width: "calc(40vh)", display: 'flex', flexDirection: 'column', backgroundColor: '#222233', paddingTop: 'calc(1.5vh)', paddingBottom: 'calc(1.5vh)', borderRadius: 20, justifyContent: 'center', alignItems: 'center' }}>
              {android ?

                <a
                  href="https://play.google.com/store/apps/details?id=com.dately"

                  style={{ textDecoration: 'none', background: "#BBF442", width: "calc(37vh)", height: 50, borderRadius: 13, alignContent: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: 15 }}>

                  <img src={androidLogo} style={{ height: "calc(3.3vh)", width: "calc(3.3vh)", paddingTop: 3, paddingRight: 8, marginLeft: -5 }} alt="androidLogo" />

                  <a style={{ textDecoration: "none", color: "#10101B", fontFamily: 'Inter', fontWeight: 600, fontSize: "calc(3.0vh)", letterSpacing: -1 }}>{strings.downloadNow}</a>

                </a> :

                <a
                  href="https://apps.apple.com/app/turn-up/id1353082383"
                  style={{ textDecoration: 'none', background: "#BBF442", width: "calc(37vh)", height: 50, borderRadius: 13, alignContent: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: 15 }}>

                  <img src={appleLogo} style={{ height: "calc(3.3vh)", width: "calc(3.3vh)", paddingBottom: 3, paddingRight: 8, marginLeft: -5 }} alt="appleLogo" />

                  <a style={{ textDecoration: "none", color: "#10101B", fontFamily: 'Inter', fontWeight: 600, fontSize: "calc(3.0vh)", letterSpacing: -1 }}>{strings.downloadNow}</a>

                </a>}

              <a style={{ fontFamily: 'Inter', color: "#E7E7FF", fontWeight: 400, fontSize: "calc(2.5vh)", textAlign: 'center', width: "calc(36vh)", letterSpacing: -0.6 }}>{strings.joinThe}<a style={{ fontWeight: 600 }}>{strings.number}</a><a>{strings.musicLovers}</a></a>

            </div>

          </div>


          <div style={{ backgroundImage: `url(${backgroundPhone})`, backgroundSize: '100%', paddingBottom: 'calc(10vh)' }}>

            {fr ?
              <div>
                <h1 style={{ marginBottom: 15, fontFamily: 'Inter', color: "#E7E7FF", fontWeight: 400, fontSize: "calc(4.3vh)", textAlign: 'center', letterSpacing: -1.5, lineHeight: 1.2 }}>{strings.yourReviews}</h1>
                <div style={{ display: "flex", flex: 1, alignItems: "center", flexDirection: 'column', overflow: 'auto' }}>
                  <div style={{ display: "flex", flexDirection: "row", overflow: 'auto', whiteSpace: 'nowrap', alignSelf: 'flex-start' }}>

                    <img style={{ height: 240, width: "auto", marginLeft: 20, marginTop: 5, marginBottom: 5, marginRight: 5 }} src={COMFR1} alt="REVIEW1" />
                    <img style={{ height: 240, width: "auto", margin: 5 }} src={COMFR2} alt="REVIEW2" />
                    <img style={{ height: 240, width: "auto", margin: 5 }} src={COMFR3} alt="REVIEW3" />
                    <img style={{ height: 240, width: "auto", margin: 5 }} src={COMFR4} alt="REVIEW4" />
                    <img style={{ height: 240, width: "auto", margin: 5 }} src={COMFR5} alt="REVIEW5" />
                    <img style={{ height: 240, width: "auto", margin: 5 }} src={COMFR6} alt="REVIEW6" />
                    <img style={{ height: 240, width: "auto", margin: 5 }} src={COMFR7} alt="REVIEW7" />
                    <img style={{ height: 240, width: "auto", margin: 5 }} src={COMFR8} alt="REVIEW8" />
                    <img style={{ height: 240, width: "auto", margin: 5 }} src={COMFR9} alt="REVIEW9" />
                    <img style={{ height: 240, width: "auto", marginLeft: 5, marginTop: 5, marginBottom: 5, marginRight: 20 }} src={COMFR10} alt="REVIEW10" />

                  </div>

                </div>
              </div>

              :

              <div>
                <h1 style={{ marginBottom: 15, marginTop: 25, fontFamily: "Inter", color: "#F1F1FF", fontWeight: 400, fontSize: "calc(4.3vh)", textAlign: 'center', letterSpacing: -1.5, lineHeight: 1.2, }}>{strings.yourReviews}</h1>
                <div style={{ display: "flex", flex: 1, alignItems: "center", flexDirection: 'column', overflow: 'auto' }}>
                  <div style={{ display: "flex", flexDirection: "row", overflow: 'auto', whiteSpace: 'nowrap', alignSelf: 'flex-start' }}>

                    <img style={{ height: 180, width: "auto", marginLeft: 20, marginTop: 5, marginBottom: 5, marginRight: 5 }} src={COMEN1} alt="REVIEW1" />
                    <img style={{ height: 180, width: "auto", margin: 5 }} src={COMEN2} alt="REVIEW2" />
                    <img style={{ height: 180, width: "auto", margin: 5 }} src={COMEN3} alt="REVIEW3" />
                    <img style={{ height: 180, width: "auto", margin: 5 }} src={COMEN4} alt="REVIEW4" />
                    <img style={{ height: 180, width: "auto", margin: 5 }} src={COMEN5} alt="REVIEW5" />
                    <img style={{ height: 180, width: "auto", margin: 5 }} src={COMEN6} alt="REVIEW6" />
                    <img style={{ height: 180, width: "auto", margin: 5 }} src={COMEN7} alt="REVIEW7" />
                    <img style={{ height: 180, width: "auto", margin: 5 }} src={COMEN8} alt="REVIEW8" />
                    <img style={{ height: 180, width: "auto", marginRight: 20, marginTop: 5, marginBottom: 5, marginLeft: 5 }} src={COMEN9} alt="REVIEW9" />



                  </div>

                </div>
              </div>
            }

          </div>

          {fr ? <div style={{ display: "flex", flex: 1, backgroundColor: '#222233', borderTopRightRadius: 30, borderTopLeftRadius: 30, justifyContent: "center", alignItems: "center", flexDirection: "column", paddingBottom: 100, overflow: "hidden" }}>

            <div >
              <h1 style={{ fontFamily: "Inter", color: "#E7E7FF", fontWeight: 400, fontSize: "calc(4.3vh)", width: "calc(200vh)", letterSpacing: -1.5, lineHeight: 1.2, textAlign: 'center', marginBottom: -5 }}>{strings.press}</h1>
            </div>
            <div style={{ flexDirection: 'column', display: 'inline-flex', padding: 20 }}>
              <a style={{ textDecoration: "none", color: "#10101B", padding: 12, flex: 33 }} href="https://www.konbini.com/fr/musique/application-de-rencontres-va-vous-aider-a-trouver-votre-moitie-en-festival/">
                <div style={{ backgroundColor: "#F1F1FF", display: "flex", borderRadius: 20, alignContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 20 }}>
                  <img style={{ height: 120, width: "auto" }} src={konbini} alt="konbini" />
                  <p style={{ fontFamily: "Inter", fontWeight: 400, letterSpacing: -0.6, fontSize: "calc(2.5vh)", width: "calc(35vh)", lineHeight: 'calc(3.3vh)' }}>
                    "Cette invention offerte par les développeurs est parfaite pour ceux qui ont toujours rêvé d’un date en festival ou pour ceux qui en on marre de louper des événements car leurs amis ne partagent pas la même passion qu’eux. Cette application devrait donc
                    vous donner le sourire."</p></div>
              </a>
              <a style={{ textDecoration: "none", color: "#10101B", padding: 12, flex: 33 }} href="https://nuit.lebonbon.fr/news-nuit/adopte-une-technophile-un-tranceux-ou-un-edm-addict-avec-ce-tinder-2-0/">
                <div style={{ backgroundColor: "#F1F1FF", display: "flex", borderRadius: 20, alignContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 20 }}>
                  <img style={{ height: 120, width: "auto" }} src={lebonbon} alt="lebonbon" />
                  <p style={{ fontFamily: "Inter", fontWeight: 400, letterSpacing: -0.6, fontSize: "calc(2.5vh)", width: "calc(35vh)", lineHeight: 'calc(3.3vh)' }}>
                    “Le petit plus, c’est que tu trouveras dans tes propositions uniquement des personnes qui ont les mêmes centres d’intérêts musicaux que toi. [...] Et Turn Up ne s’arrête pas là, puisqu’elle te permet aussi de découvrir ou organiser des évènements près
                    de chez toi, avec d’autres utilisateurs de l’application. [...] Magical !”</p></div>
              </a>
              <a style={{ textDecoration: "none", color: "#10101B", padding: 12, flex: 33 }} href="https://www.madmoizelle.com/turn-up-application-rencontres-1042436">
                <div style={{ backgroundColor: "#F1F1FF", display: "flex", borderRadius: 20, alignContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 20 }}>
                  <img style={{ height: 120, width: "auto" }} src={madmoizelle} alt="madmoizelle" />
                  <p style={{ fontFamily: "Inter", fontWeight: 400, letterSpacing: -0.6, fontSize: "calc(2.5vh)", width: "calc(35vh)", lineHeight: 'calc(3.3vh)' }}>
                    "Souvent, avec une passion pour un genre musical, viennent de vrais codes sociaux, un réel univers, et je trouve ça tellement agréable et précieux de pouvoir partager ces moments avec sa moitié ou son plan cul !"</p></div>
              </a>

              <a style={{ textDecoration: "none", color: "#10101B", padding: 12, flex: 33 }} href="https://www.femmeactuelle.fr/amour/news-amour/tinder-musical-cette-appli-vous-permet-de-vous-rencontrer-a-des-concerts-2091039">
                <div style={{ backgroundColor: "#F1F1FF", display: "flex", borderRadius: 20, alignContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 20 }}>
                  <img style={{ height: 120, width: "auto" }} src={femmeactuelle} alt="femmeactuelle" />
                  <p style={{ fontFamily: "Inter", fontWeight: 400, letterSpacing: -0.6, fontSize: "calc(2.5vh)", width: "calc(35vh)", lineHeight: 'calc(3.3vh)' }}>
                    "Célibataire, en quête du grand amour ou de nouvelles rencontres ? Cette application pourrait bien vous donner un coup de pouce lors d’un concert ou d’un festival, en vous permettant de rentrer en contact avec des personnes qui partagent la même passion
                    que vous pour la musique !"</p></div>
              </a>
              <a style={{ textDecoration: "none", color: "#10101B", padding: 12, flex: 33 }} href="https://www.traxmag.com/turn-up-le-tinder-pour-les-amoureux-de-musique-vient-de-sortir/">
                <div style={{ backgroundColor: "#F1F1FF", display: "flex", borderRadius: 20, alignContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 20 }}>
                  <img style={{ height: 120, width: "auto" }} src={trax} alt="trax" />
                  <p style={{ fontFamily: "Inter", fontWeight: 400, letterSpacing: -0.6, fontSize: "calc(2.5vh)", width: "calc(35vh)", lineHeight: 'calc(3.3vh)' }}>
                    “Turn Up, voici le nom de cette nouvelle application de rencontre en milieu festif. Axée sur la musique, il est possible de sélectionner son style de prédilection et ainsi programmer son rendez-vous en club, rave ou festival.”</p></div>
              </a>
              <a style={{ textDecoration: "none", color: "#10101B", padding: 12, flex: 33 }} href="https://www.nrj.be/article/2522/turn-up-le-tinder-des-salles-de-concert">
                <div style={{ backgroundColor: "#F1F1FF", display: "flex", borderRadius: 20, alignContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 20 }}>
                  <img style={{ height: 120, width: "auto" }} src={nrj} alt="nrj" />
                  <p style={{ fontFamily: "Inter", fontWeight: 400, letterSpacing: -0.6, fontSize: "calc(2.5vh)", width: "calc(35vh)", lineHeight: 'calc(3.3vh)' }}>
                    "Pour vous, la musique est essentielle ? Vous ne tomberiez jamais amoureux de quelqu’un qui a des goûts musicaux opposés au vôtre ? Cette app est faite pour vous !"</p></div>
              </a>
            </div>
          </div>
            : <div></div>}
          <div style={{ backgroundColor: '#222233', flex: 1, display: "flex", overflow: "hidden", width: '100%', paddingBottom: 10 }}>
            <div style={{ flex: 1, display: "flex", justifyContent: 'space-around', width: '100%' }}>
              <button style={{ color: "#F1F1FF", fontFamily: "Inter", fontSize: "calc(1.8vh)", background: "none", border: "none", padding: 0, cursor: "pointer", outline: "none", textDecoration: "underline" }} onClick={goToPrivacyPolicy}>
                {strings.mentions}
              </button>
              <button style={{ color: "#F1F1FF", fontFamily: "Inter", fontSize: "calc(1.8vh)", background: "none", border: "none", padding: 0, cursor: "pointer", outline: "none", textDecoration: "underline" }} onClick={goToCGU}>
                {strings.cgu}
              </button>
              <button style={{ color: "#F1F1FF", fontFamily: "Inter", fontSize: "calc(1.8vh)", background: "none", border: "none", padding: 0, cursor: "pointer", outline: "none", textDecoration: "underline" }} onClick={goToPrivacyPolicy}>
                {strings.charte}
              </button>
            </div>
          </div>
        </div>
      </TabletAndBelow>
    </div>
  );
}

export default Home;

let strings = new LocalizedStrings({
  en: {
    title: "Match through music !",
    download: "Download",
    subTitle: "Discover the app that uses your music taste to find you dates and friends.",
    android: "Download on Android",
    downloadNow: "Download now",
    yourReviews: "Your reviews",
    appStoreDownload: "Download on the",
    appStore: "App store",
    googlePlayDownload: "Get it on",
    googlePlay: "Google Play",
    iOS: "Download on iOS",
    joinThe: "Join the",
    number: " 2,000,000 ",
    musicLovers: "music lovers!",
    mentions: "Legal information",
    cgu: "Terms of use",
    charte: "Privacy policy",
    taste: "For all tastes and colors",
    press: "They talked about us",
    language: "en",

  },
  fr: {
    title: "Match en musique !",
    subTitle: "Découvrez l'application pour rencontrer des célibataires grâce à vos goûts musicaux.",
    android: "Téléchargez sur Android",
    iOS: "Téléchargez sur iOS",
    downloadNow: "Télécharger",
    yourReviews: "Vos avis",
    joinThe: "Rejoins les",
    number: " 2,000,000 ",
    musicLovers: "amoureux de musique !",
    mentions: "Mentions légales",
    cgu: "CGU",
    charte: "Charte de confidentialité",
    download: "Télécharger",
    taste: "Des profils pour tous les goûts",
    press: "Ils parlent de nous",
    language: "fr",
    appStoreDownload: "Télécharger dans",
    appStore: "App store",
    googlePlayDownload: "Disponible sur",
    googlePlay: "Google Play",
  },
});
